<template>
  <PageContent
    :can-get-user-banner="true"
    :can-set-description-and-title="false"
    :can-set-meta-image="false"
    :full-screen="isFullScreen"
  >
    <div
      :class="mapContainerClass"
    >
      <Map
        v-if="canShowAuthContent && mapProps"
        v-bind="mapProps"
        @map-loaded="handleMapLoaded"
        @unable-to-load-map="handleUnableToLoadMap"
      />
    </div>
    <MapFooter
      v-if="!isFullScreen"
      class="tw-my-5"
    />
    <RemoveCustomLocationDialog
      :show-dialog="showConfirmDialog"
      @close="handleConfirmDialogClose"
    />
    <WhatsNewDialog
      :show-dialog="showWhatsNewDialog"
      @close="handleWhatsNewDialogClose"
    />
    <WeatherStationDialog
      :in-map-page="true"
    />
  </PageContent>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { getDescription, getTitle } from '@@/utils/PageUtils';
import { mapCardTypes } from '@@/stores/Map';
import AirQualityOverlay from '@@/components/Maps/Overlays/AirQualityOverlay.vue';
import AirQualityForecastOverlay from '@@/components/Maps/Overlays/AirQualityForecastOverlay.vue';
import AvalancheOverlay from '@@/components/Maps/Overlays/AvalancheOverlay.vue';
import CloudCoverOverlay from '@@/components/Maps/Overlays/CloudCoverOverlay.vue';
import FavoritesStorageMixin from '@@/components/Favorites/FavoritesStorageMixin';
import ForecastRadarOverlay from '@@/components/Maps/Overlays/ForecastRadarOverlay.vue';
import HailOverlay from '@@/components/Maps/Overlays/HailOverlay.vue';
import LightningNowcastOverlay from '@@/components/Maps/Overlays/LightningNowcastOverlay.vue';
import LocationsOverlay from '@@/components/Maps/Overlays/LocationsOverlay.vue';
import OverlayNames from '@@/components/Maps/Overlays/OverlayNames';
import ParcelsOverlay from '@@/components/Maps/Overlays/ParcelsOverlay.vue';
import PublicLandsOverlay from '@@/components/Maps/Overlays/PublicLandsOverlay.vue';
import RadarOverlay from '@@/components/Maps/Overlays/RadarOverlay.vue';
import RadarGlobalOverlay from '@@/components/Maps/Overlays/RadarGlobalOverlay.vue';
import RadarJapanOverlay from '@@/components/Maps/Overlays/RadarJapanOverlay.vue';
import SmokeSkyOverlay from '@@/components/Maps/Overlays/SmokeSkyOverlay.vue';
import SmokeSkyHighResOverlay from '@@/components/Maps/Overlays/SmokeSkyHighResOverlay.vue';
import SmokeSurfaceOverlay from '@@/components/Maps/Overlays/SmokeSurfaceOverlay.vue';
import SmokeSurfaceHighResOverlay from '@@/components/Maps/Overlays/SmokeSurfaceHighResOverlay.vue';
import SnowDepthOverlay from '@@/components/Maps/Overlays/SnowDepthOverlay.vue';
import SnowFall24HourOverlay from '@@/components/Maps/Overlays/SnowFall24HourOverlay.vue';
import SnowFallSeasonOverlay from '@@/components/Maps/Overlays/SnowFallSeasonOverlay.vue';
import ForecastSnowfallOverlay from '@@/components/Maps/Overlays/ForecastSnowfallOverlay.vue';
import TemperatureOverlay from '@@/components/Maps/Overlays/TemperatureOverlay.vue';
import WeatherStationsOverlay from '@@/components/Maps/Overlays/WeatherStationsOverlay.vue';
import WildfireOverlay from '@@/components/Maps/Overlays/WildfireOverlay.vue';
import WindOverlay from '@@/components/Maps/Overlays/WindOverlay.vue';
import FallColorsOverlay from '@@/components/Maps/Overlays/FallColorsOverlay.vue';
import { useAsyncData } from 'nuxt/app';

export default {
  name: 'MapPage',

  mixins: [FavoritesStorageMixin],

  async setup() {
    const nuxtApp = useNuxtApp();

    const config = useRuntimeConfig().public;
    const mapStore = useMapStore();
    const route = useRoute();

    const ogImage = ref(config.ogImage);
    const description = ref(getDescription({ config, path: route.path }));
    const title = ref(getTitle({ config, path: route.path }));

    const parseNumbersFromQuery = (str) => {
      try {
        const numbers = str.split(',').map((n) => Number(n));

        if (numbers.some((number) => isNaN(number))) {
          return null;
        }

        return numbers;
      }
      catch (e) {
        return null;
      }
    };

    let bounds = null;

    if (route.query.bounds) {
      bounds = parseNumbersFromQuery(route.query.bounds) ?? null;
    }

    let center = [-97, 40];

    if (route.query.center) {
      center = parseNumbersFromQuery(route.query.center) ?? [-97, 40];
    }

    let markerLngLat = null;

    if (route.query.markerLngLat) {
      markerLngLat = parseNumbersFromQuery(route.query.markerLngLat) ?? null;
    }

    const canSaveMapState = route.query.canSaveMapState === 'false' ? false : true;
    const newLightningRangeRingsCookie = useCookieWrapper('new-lightning-range-rings');

    // If an overlay has been selected then fetch the map sources so that the image of the
    // current overlay can be used in the ogImage meta tag in the head of the document when
    // rendered on the server side. This will display the image of the current overlay when
    // sharing a map URL that has an overlay in the path.

    if (route.params.overlay && route.params.overlay !== 'none') {
      const sources = Object.values(OverlayNames).map((name) => ({ short_name: name }));
      const payload = { sources, noCommit: true };

      const { data } = await useAsyncData(async () => {
        const response = await mapStore.fetchMapSourcesMeta(payload);
        return { response };
      });

      if (data?.value) {
        const overlay = Object.values(data.value.response.sources)
          .find((source) => source.slug === route.params.overlay);

        ogImage.value = overlay.image_url;
      }
    }

    // Ensure useSeoMeta() is called in Nuxt context in case context is reset after returning from
    // useAsyncData() above when /map page is rendered on the server side.
    // SEE: https://nuxt.com/docs/api/composables/use-nuxt-app#runwithcontext

    nuxtApp.runWithContext(() => useSeoMeta({
      description,
      ogDescription: description,
      ogImage,
      ogTitle: title,
      title,
      twitterDescription: description,
      twitterImage: ogImage,
      twitterTitle: title,
    }));

    return {
      bounds,
      canSaveMapState,
      center,
      config,
      description,
      markerLngLat,
      newLightningRangeRingsCookie,
      ogImage,
      title,
    };
  },

  data() {
    return {
      isMapLoaded: false,
      mapProps: null,
      showWhatsNewDialog: false,
      unableToLoadMap: false,
    };
  },

  computed: {
    ...mapState(useMapStore, {
      currentBaseMap: (state) => state.ui.currentBaseMap,
      isFullScreen: (state) => state.ui.isFullScreen,
      mapCard: (state) => state.ui.mapCard,
    }),

    ...mapState(useMapStore, ['currentOverlay', 'url']),
    ...mapState(useUserStore, {
      banner: (state) => state.banner,
      canShowAuthContent: (state) => state.canShowAuthContent,
    }),

    mapContainerClass() {
      const transitionClasses = [
        'tw-transition-opacity',
        this.isMapLoaded || this.unableToLoadMap ? 'tw-opacity-100' : 'tw-opacity-0',
      ];

      if (this.isFullScreen) {
        return [
          this.banner ? this.$style.fullScreenWithBanner : 'tw-h-full',
          ...transitionClasses,
        ];
      }

      return [
        this.$style.mapContainer,
        this.banner ? this.$style.mapContainerWithBanner : null,
        'lg:tw-py-10',
        ...transitionClasses,
      ];
    },

    showConfirmDialog() {
      return this.mapCard?.type === mapCardTypes.removeCustomLocationDialog;
    },
  },

  watch: {
    currentOverlay() {
      if (this.currentOverlay) {
        this.ogImage = this.currentOverlay.image_url;
      }
      else {
        this.ogImage = this.$config.ogImage;
      }

      if (this.currentOverlay?.slug === 'lightning-risk'
        && this.currentOverlay?.is_new
        && !this.newLightningRangeRingsCookie) {
        this.showWhatsNewDialog = true;
      }
    },

    url() {
      if (this.url) {
        const { config } = this;
        const path = this.url;
        const params = { config, path };

        this.description = getDescription(params);
        this.title = getTitle(params);
      }
    },
  },

  mounted() {
    this.mapProps = {
      bounds: this.bounds,
      canBeFullScreen: true,
      canSaveMapState: this.canSaveMapState,
      canSelectBaseMaps: true,
      canSelectLocationTypes: true,
      canSelectOverlays: true,
      canShowForecastAnywhere: true,
      canViewFullScreen: true,
      center: this.center,
      markerLngLat: this.markerLngLat,
      // Mark overlay components with useRaw() so they can be passed in props to <Map>
      // SEE:
      // - https://stackoverflow.com/questions/73236258/vuejs-3-vue-received-a-component-which-was-made-a-reactive-object-which-is-alr
      // - https://vuejs.org/api/reactivity-advanced.html#markraw
      overlays: [
        markRaw(AirQualityOverlay),
        markRaw(AirQualityForecastOverlay),
        markRaw(AvalancheOverlay),
        markRaw(CloudCoverOverlay),
        markRaw(ForecastRadarOverlay),
        markRaw(HailOverlay),
        markRaw(LightningNowcastOverlay),
        markRaw(LocationsOverlay),
        markRaw(ParcelsOverlay),
        markRaw(PublicLandsOverlay),
        markRaw(RadarOverlay),
        markRaw(RadarGlobalOverlay),
        markRaw(RadarJapanOverlay),
        markRaw(SmokeSkyOverlay),
        markRaw(SmokeSkyHighResOverlay),
        markRaw(SmokeSurfaceOverlay),
        markRaw(SmokeSurfaceHighResOverlay),
        markRaw(SnowDepthOverlay),
        markRaw(SnowFall24HourOverlay),
        markRaw(SnowFallSeasonOverlay),
        markRaw(ForecastSnowfallOverlay(OverlayNames.snowTotal)),
        markRaw(ForecastSnowfallOverlay(OverlayNames.snow6h)),
        markRaw(ForecastSnowfallOverlay(OverlayNames.snow12h)),
        markRaw(ForecastSnowfallOverlay(OverlayNames.snow24h)),
        markRaw(TemperatureOverlay),
        markRaw(WeatherStationsOverlay),
        markRaw(WildfireOverlay),
        markRaw(WindOverlay),
        markRaw(FallColorsOverlay),
      ],
      shouldReplaceUrl: true,
      shouldShowTooltip: true,
      shouldShowLocations: true,
      zoom: 3,
    };
  },

  unmounted() {
    this.resetState();
  },

  methods: {
    ...mapActions(useMapStore, ['setMapCard', 'setMapUiProperties', 'resetState']),

    async handleConfirmDialogClose(canContinue) {
      if (canContinue) {
        const { location } = this.mapCard?.data || {};
        const locationFavoriteLists = this.getFavoriteListsForLocation(location);
        await this.removeFavorite({ location }, locationFavoriteLists);
      }

      this.setMapUiProperties({ mapCard: null });
    },

    handleMapLoaded() {
      this.isMapLoaded = true;
    },

    handleUnableToLoadMap() {
      this.unableToLoadMap = true;
    },

    handleWhatsNewDialogClose() {
      this.newLightningRangeRingsCookie = true;
      this.showWhatsNewDialog = false;
    },
  },
};
</script>

<style module>
.mapContainer {
  height: calc((100 * var(--vh, 1vh)) - 12rem);
  max-height: 1400px;
  min-height: 400px;
  width: 100%;
}

@media (min-width: 992px) {
  .mapContainer {
    height: calc((100 * var(--vh, 1vh)) - 5rem);
  }
}

.mapContainerWithBanner {
  /* - 7rem for small screen height of banner */
  height: calc((100 * var(--vh, 1vh)) - 12rem - 7rem);
}

@media (min-width: 992px) {
  .mapContainerWithBanner {
    /* - 5rem for medium+ screen height of banner */
    height: calc((100 * var(--vh, 1vh)) - 12rem - 5rem);
  }
}

.fullScreenWithBanner {
  /* - 7rem for small screen height of banner */
  height: calc(100% - 8.5rem);
}

@media (min-width: 992px) {
  .fullScreenWithBanner {
    /* - 5rem for medium+ screen height of banner */
    height: calc(100% - 6.5rem);
  }
}
</style>
