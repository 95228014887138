<script>
// import { MercatorCoordinate, OverscaledTileID } from 'mapbox-gl';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import { addSource, colorInterpolate } from './Utils';

export default function (shortName) {
  return {
    name: 'ForecastSnowfallOverlay',

    mixins: [
      AnimatedOverlayMixin,
      OpacityMixin,
      OverlayMixin,
    ],

    data() {
      return {
        frames: [],
        isAllAccess: true,
        isForecast: true,
        source: {
          get_forecasts: true,
          has_legend: true,
          has_mask: true,
          has_tile_index: true,
          short_name: shortName,
          tile_count: 40,
          ranges: [{ start: -15, stop: 240, step: 1 }],
          tile_sort: 'asc',
          tile_types: ['rasters'],
        },
      };
    },
    methods: {
      async add() {
        await this.updateMapSourceAndFrames();
        this.startStaleCheckInterval();

        if (this.frames.length > 0) {
          this.show();
          return;
        }

        this.addOverlayMask();

        this.tiles.forEach((tile) => this.addFrame(tile));

        this.show();
      },
      updateLayerDisplay() {
        // const currentSourceId = this.frames[this.currentFrame].source;
        // console.log(currentSourceId);
        // const cache = this.map.style._sourceCaches[`other:${currentSourceId}`];
        // const t = this.map.transform;
        // const pt = MercatorCoordinate.fromLngLat(this.map.getCenter());
        // console.log(pt);
        // // t.coveringTiles();
        // // cache.getTileByID();
        // console.log(cache);
        // const z = cache.getSource().maxzoom;
        // const tiles = 1 << z;
        // const wrap = Math.floor(pt.x);
        // const px = pt.x - wrap;
        // const x = Math.floor(px * tiles);
        // const y = Math.floor(pt.y * tiles);
        // console.log(OverscaledTileID);
        // console.log(tiles, y * tiles + x);
        // console.log({ z, wrap, z, x, y });
        // console.log(cache.getSource().maxzoom);
      },
      addFrame(tile) {
        /* eslint camelcase: off */
        const { source_timestamp, source_timestamp_range } = tile;
        // tile.index_json.url = 'https://tbar.nyc3.cdn.digitaloceanspaces.com/tiles/c9-snowfall-total-1737067774-67898d173f923/opensnow.json';
        // tile.index_json.url = 'https://tbar.nyc3.cdn.digitaloceanspaces.com/tiles/c9-snowfall-total-1737067439-67898bc73999d/opensnow.json';
        // tile.index_json.url = 'https://tbar.nyc3.cdn.digitaloceanspaces.com/tiles/c9-snowfall-total-1737402479-678ea888dccde/opensnow.json';
        const rasterSource = addSource({ tile, type: 'raster' }, this);

        if (!rasterSource) {
          return;
        }

        const raster = this.addRaster(tile);

        this.frames.push({
          layer: raster,
          sources: [rasterSource],
          source_timestamp,
          source_timestamp_range,
        });
      },
      getRasterColor(displayType = 'step') {
        const colorInterp = colorInterpolate(this.mapSource.legends[0].steps);
        if (displayType === 'step') {
          return [
            'step',
            ['raster-value'],
            ['rgba', 0, 0, 0, 0],
            ...colorInterp,
          ];
        }
        else {
          return [
            'interpolate',
            ['linear'],
            ['raster-value'],
            ...colorInterp,
          ];
        }
      },
      getRasterResampling() {
        return this.displayOption[0] === 'n' ? 'nearest' : 'linear';
      },
      addRaster(tile) {
        const layerBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'contour-line';
        const { name } = tile.index_json;
        const rasterConfig = tile.raster_render_config;
        const rasterColorization = {};
        if (rasterConfig) {
          rasterColorization['raster-color'] = this.getRasterColor(rasterConfig?.raster_color_type ?? 'step');
          rasterColorization['raster-color-range'] = rasterConfig?.raster_color_range ?? [0, 110];
          rasterColorization['raster-resampling'] = rasterConfig?.resampling ?? 'linear';
          rasterColorization['raster-color-mix'] = rasterConfig?.raster_color_mix ?? [255 / (255 / 150), 1 / (255 / 150), 0, 0];
        }
        this.map.addLayer({
          id: name,
          type: 'raster',
          source: name,
          paint: {
            'raster-opacity-transition': {
              duration: 0,
              delay: 0,
            },
            'raster-fade-duration': 1000,
            'raster-opacity': 0,
            ...rasterColorization,
          },
        }, layerBelow);
        return { id: name, opacities: ['raster-opacity'] };
      },
    },
  };
};
</script>
