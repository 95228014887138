<template>
  <Card
    card-class-names="tw-pt-4 lg:tw-pt-6"
    :has-body-padding-small="true"
    header-class-name="tw-px-2 tw-py-4"
  >
    <template #body>
      <div
        v-for="chart in charts"
        :key="chart.id"
        :class="getChartClass(chart)"
      >
        <WeatherStationChartLabel
          :description="chart.description"
          :is-estimated="chart.isEstimated"
          :legend-items="chart.legendItems"
        />
        <div :class="$style.chart">
          <canvas :id="chart.id" />
        </div>
      </div>
      <UpgradeToAllAccess
        v-if="charts.length && !isAllAccess"
        header-text="Want to see all the weather station charts?"
      />
      <WeatherStationNoData
        v-if="charts.length === 0"
      />
      <WeatherStationFooter />
    </template>
  </Card>
</template>

<script>
/* eslint camelcase: off */

import { mapState } from 'pinia';
import debounce from 'lodash.debounce';
import {
  canBeResponsive,
  chartColors,
  getTooltipOptions,
  getMaxValue,
  getMinValue,
} from '@@/utils/ChartUtils';
import {
  findMode,
  formatForecastDate,
  formatSlr,
  formatSnow,
  getTemperatureUnits,
  getWindUnits,
  isEstimatedSource,
  isScreenLg,
  isScreenSm,
} from '@@/utils/CommonUtils';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';
import SnowObservationAlertMixin from '@@/components/WeatherStations/SnowObservationAlertMixin';
import WeatherStationMixin from '@@/components/WeatherStations/WeatherStationMixin';

export default {
  name: 'WeatherStationCharts',

  mixins: [SnowObservationAlertMixin, WeatherStationMixin],

  computed: {
    ...mapState(useMetaStore, ['dataSourcesById']),
    ...mapState(useUserStore, ['isAllAccess']),

    ...mapState(useUserStore, {
      theme: (state) => state.preferences.theme,
      units: (state) => state.preferences.units,
    }),

    charts() {
      const charts = this.weatherStation.station_variables.map((stationVariable) => {
        const {
          chart_type,
          description,
          display_name,
          name,
          source_id,
          sort_weight,
          units,
        } = stationVariable;

        if (name === 'wind_gust_speed') {
          return null;
        }

        const chart = {
          chart_type,
          description,
          id: `${name}_chart`,
          isEstimated: isEstimatedSource(source_id, this.dataSourcesById),
          legendItems: [{
            color: chartColors.saturatedBlue,
            text: units ? `${display_name} (${units})` : display_name,
          }],
          sort_weight,
          variable: name,
        };

        return chart;
      });

      if (this.hasWindSpeed && this.hasWindGustSpeed) {
        const { display_name, units } = this.weatherStation.station_variables
          .find(({ name }) => name === 'wind_gust_speed');

        const windSpeedChart = charts.find(({ variable }) => variable === 'wind_speed');

        windSpeedChart.legendItems.push({
          color: chartColors.saturatedRed,
          text: `${display_name} (${units})`,
        });
      }

      // Remove the null chart that was added for wind_gust_speed, remove charts not
      // available to free users, and then then sort the charts in the desired output order.
      return charts
        .filter((chart) => chart !== null)
        .filter((chart) => {
          if (this.isAllAccess) {
            return true;
          }

          return chart.variable === 'temp';
        })
        .sort((a, b) => (a.sort_weight - b.sort_weight));
    },

    /**
     * Return the minimum report interval across all reported variables. The report interval
     * is used to determine how frequently to display points. Note that stations may not report all
     * variables at the same frequency, and variables that are reported less frequently will have
     * null values in each record and in the charts. So the minimum report interval is used across
     * _all_ variables when choosing which points to make visible in getConfig().
     */
    reportInterval() {
      const reportIntervals = this.weatherStation.station_variables
        .map(({ name }) => this.getReportInterval(name));

      return Math.min(...reportIntervals);
    },
  },

  watch: {
    async charts() {
      // Re-render the charts _after_ the next tick so that any new <canvas> elements can be
      // added to the DOM before creating Chart.js charts that use them.
      await this.$nextTick();
      this.renderCharts();
    },

    async theme() {
      if (this.hasWindDir) {
        this.windDirImage = await this.loadWindDirImage();
      }

      this.renderCharts();
    },
  },

  async mounted() {
    if (this.hasWindDir) {
      this.windDirImage = await this.loadWindDirImage();
    }

    this.renderCharts();
  },

  methods: {
    createChart(chart, config) {
      const { id } = chart;
      let canvas = document.getElementById(id);

      if (this[id]) {
        // After destroying the chart remove the <canvas> element and add a new one and then
        // re-render the chart in a timeout. Seems extreme, I know.
        // SEE: https://stackoverflow.com/questions/40056555/destroy-chart-js-bar-graph-to-redraw-other-graph-in-same-canvas
        this[id].destroy();
        this[id] = null;

        if (canvas) {
          const { parentElement } = canvas;
          parentElement.removeChild(canvas);
          canvas = document.createElement('canvas');
          canvas.setAttribute('id', id);
          parentElement.appendChild(canvas);
        }

        // TODO: The timeout handler may accidentally be called after leaving the page! Need to
        // guard against that.
        window.setTimeout(() => this.createChart(chart, config));
        return;
      }

      this[id] = new this.$chart(canvas, config);
    },

    getChartClass(chart) {
      return [
        'tw-w-full',
        chart.description ? 'tw-h-48 lg:tw-h-60' : 'tw-h-49 lg:tw-h-52',
        'tw-mb-4 lg:tw-mb-6 last:tw-mb-0',
        'tw-pb-4 lg:tw-pb-6',
        this.isAllAccess ? 'tw-border-b last:tw-border-b-0 border-color' : '',
      ];
    },

    getConfig({ chart_type = 'line', variable }) {
      const config = {
        data: {
          datasets: [],
          labels: this.getLabels(),
        },
        options: {
          clip: false, // Don't clip points at bottom/top of y-axis
          layout: {
            padding: {
              right: 8,
            },
          },
          maintainAspectRatio: false,
          // Debounce the onresize handler since resizeDelay doesn't seem to do this.
          // SEE: https://www.chartjs.org/docs/latest/configuration/responsive.html#configuration-options
          onResize: debounce((chart) => this.handleResize(chart), 500),
          plugins: {
            // Use annotation plug-in to alternate background colors to distinguish day/night.
            // SEE: https://stackoverflow.com/questions/70686455/chart-js-alternating-background-color-for-ticks
            annotation: {
              annotations: this.observation
                .map(({ day_period }, index) => ({
                  backgroundColor: chartColors.barBackground(this.theme),
                  borderWidth: 0,
                  drawTime: 'beforeDraw',
                  day_period,
                  type: 'box',
                  xMax: index === this.observation.length - 1 ? index : index + 0.5,
                  xMin: index === 0 ? 0 : index - 0.5,
                  xScaleID: 'x',
                  yScaleID: 'y',
                }))
                .filter(({ day_period }) => day_period === 'night'),
            },
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltip: {
              ...getTooltipOptions(),
              axis: 'x',
              callbacks: {
                title: () => '',
              },
              displayColors: false,
              intersect: false,
              mode: 'index',
            },
          },
          responsive: canBeResponsive(),
          scales: {
            x: this.getXAxis(),
            y: this.getYAxis(),
          },
        },
        type: chart_type,
      };

      if (chart_type === 'line') {
        // Filter points displayed in line chart so they're not too crowded using the following
        // guidelines:
        //
        //         Minutes b/t Points
        // Hours | Desktop  |  Mobile
        // --------------------------
        //    24 |       30 |      60
        //    48 |       60 |     120
        //    72 |       90 |     180
        //   120 |      150 |     300
        //   168 |      210 |     420

        const pointStyle = [];
        const radius = [];

        const requiredMinutesBetweenPoints = (() => {
          if (this.hours === 24) return isScreenSm() ? 60 : 30;
          if (this.hours === 48) return isScreenSm() ? 120 : 60;
          if (this.hours === 72) return isScreenSm() ? 180 : 90;
          if (this.hours === 120) return isScreenSm() ? 300 : 150;
          if (this.hours === 168) return isScreenSm() ? 420 : 210;
          return 0;
        })();

        let minutesBetweenPoints = 0;

        this.observation.forEach((observation) => {
          const value = observation[variable];

          if (minutesBetweenPoints === 0 && value === null) {
            // Ignore and hide points before the first reported observation of the variable
            pointStyle.push(false);
            radius.push(0);
          }
          else if (minutesBetweenPoints === 0) {
            // Show the first point
            pointStyle.push('circle');
            radius.push(4);
            minutesBetweenPoints += this.reportInterval;
          }
          else if (minutesBetweenPoints >= requiredMinutesBetweenPoints && value !== null) {
            // Show the next point with a value after the required minutes between points and
            // restart the count
            pointStyle.push('circle');
            radius.push(4);
            minutesBetweenPoints = this.reportInterval;
          }
          else {
            // Hide points that occur before the required minutes between points
            pointStyle.push(false);
            radius.push(0);
            minutesBetweenPoints += this.reportInterval;
          }
        });

        config.options.elements = {
          line: {
            borderWidth: 4,
            spanGaps: false,
          },
          point: {
            borderWidth: 0,
            pointStyle,
            radius,
          },
        };
      }
      else if (chart_type === 'bar') {
        config.options.datasets = {
          bar: {
            barPercentage: 0.85,
            categoryPercentage: 1,
          },
        };
      }

      return config;
    },

    getDataset(variable, chartColor = chartColors.saturatedBlue, type = 'line') {
      const data = this.observation.map((observation) => ({
        x: Date.parse(observation.display_at),
        y: observation[variable],
      }));

      const dataset = {
        backgroundColor: chartColor,
        borderColor: chartColor,
        data,
        label: variable,
        lineBorderWidth: 2,
      };

      if (type === 'bar') {
        dataset.borderRadius = { topLeft: 2, topRight: 2 };
        dataset.borderWidth = 0;
        dataset.minBarLength = 2;
      }

      return dataset;
    },

    getLabels() {
      // Populate the initial labels labeling the first tick, and ticks for new hours.
      //
      // A weather station may return multiple observations for each hour, all of which have the
      // same display_at_local_label, e.g. '8am', which should only be displayed once at the
      // beginning of the hour.

      let lastLabel = '';

      const labels = this.observation.reduce((acc, observation) => {
        const { display_at_local_label } = observation;

        if (acc.length === 0) {
          // Label first tick
          acc.push(display_at_local_label);
        }
        else if (lastLabel !== display_at_local_label) {
          // Label tick for new hours
          acc.push(display_at_local_label);
        }
        else {
          // Don't display duplicate labels if the station reports more than once an hour
          acc.push('');
        }

        lastLabel = display_at_local_label;

        return acc;
      }, []);

      //
      // Skip labels based on number of hours and screen size
      //

      const maxLabels = isScreenSm() ? 8 : 24;
      const numberOfLabelsToSkip = this.hours / maxLabels;
      let prevLabelIndex;

      labels.forEach((label, index) => {
        if (label && index === 0) {
          prevLabelIndex = 1;
          return;
        }

        if (label && prevLabelIndex % numberOfLabelsToSkip !== 0) {
          prevLabelIndex += 1;
          labels[index] = '';
          return;
        }

        if (label) {
          prevLabelIndex += 1;
        }
      });

      //
      // Label first tick and ticks for new days with formatted date
      //

      const { timezone } = this.weatherStation;
      const format = isScreenSm() ? 'M/D' : 'MMM D';
      const formatDate = (displayAt) => this.$dayjs.utc(displayAt).tz(timezone).format(format);

      labels.forEach((label, index) => {
        const { display_at } = this.observation[index];

        if (index === 0) {
          labels[index] = [label, formatDate(display_at)];
          prevLabelIndex = 0;
        }
        else if (label) {
          const dateAtIndex = formatDate(display_at);
          const dateAtPrevLabelIndex = formatDate(
            this.observation[prevLabelIndex].display_at,
          );

          if (dateAtIndex !== dateAtPrevLabelIndex) {
            labels[index] = [label, dateAtIndex];
          }

          prevLabelIndex = index;
        }
      });

      return labels;
    },

    /**
     * Find the mode of the timespans between observations for the specified variable. This will be
     * used as the reporting interval for the station for the specified variable.
     */
    getReportInterval(variable) {
      // Filter the observations finding only those that have reports for the specified variable
      const observations = this.observation.reduce((acc, observation) => {
        if (typeof observation[variable] === 'number') {
          acc.push(observation.display_at);
        }

        return acc;
      }, []);

      // Reverse the observations, so that the newest are first, and then calculate the difference
      // in minutes between each observation.
      const reportIntervals = observations
        .reverse()
        .reduce((acc, observation, index) => {
          if (index === observations.length - 1) {
            return acc;
          }

          const currentObservation = this.$dayjs(observation);
          const prevObservation = this.$dayjs(observations[index + 1]);

          acc.push(currentObservation.diff(prevObservation, 'minute'));

          return acc;
        }, []);

      // Finally, find the most frequently occurring report interval between observations. We
      // chose to use the mode value as this is most representative of how frequently the station
      // reports.
      return findMode(reportIntervals);
    },

    getTooltipLabel(chart) {
      // eslint-disable-next-line
      const self = this;

      return (context) => {
        const { timezone } = self.weatherStation;
        const { display_at } = self.observation[context.dataIndex];
        const displayAtFormatted = formatForecastDate(display_at, timezone);

        let label;

        if (chart.variable === 'precip_snow' || chart.variable === 'precip_snow_sum') {
          label = formatSnow(context.raw.y, self.units, true);
        }
        else if (chart.variable === 'relative_humidity') {
          label = `${context.raw.y}%`;
        }
        else if (chart.variable === 'slr') {
          label = formatSlr(context.raw.y);
        }
        else if (chart.variable === 'snow_depth') {
          label = formatSnow(context.raw.y, self.units, true);
        }
        else if (chart.variable === 'snow_interval') {
          label = formatSnow(context.raw.y, self.units, true);
        }
        else if (chart.variable === 'swe') {
          label = formatSnow(context.raw.y, self.units, true);
        }
        else if (chart.variable === 'temp') {
          label = `${context.formattedValue} ${getTemperatureUnits(self.units)}`;
        }
        else if (chart.variable === 'wind_speed') {
          label = `${context.formattedValue} ${getWindUnits(self.units)} ${context.datasetIndex === 0 ? 'Wind Speed' : 'Wind Gust Speed'}`;
        }

        return `${label} - ${displayAtFormatted}`;
      };
    },

    getXAxis() {
      return {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          color: chartColors.textDark(this.theme),
          font: {
            size: 11,
          },
          maxRotation: 0,
          minRotation: 0,
        },
      };
    },

    getYAxis() {
      return {
        afterFit(axis) {
          // Set width of all y-axis to same size so that labels in x-axis of charts on the page are
          // aligned vertically.
          axis.width = isScreenSm() ? 32 : 35;

          // Set precision to 1 decimal if max is greater than 100 so large numbers aren't truncated
          if (axis.max > 100) {
            axis.options.ticks.precision = 1;
          }
        },
        beginAtZero: false,
        border: {
          dash({ index }) {
            return index === 0 ? false : [2, 2];
          },
          display: false,
          width: 0,
        },
        grid: {
          color: chartColors.borderColor(this.theme),
          display: true,
          drawTicks: false,
        },
        ticks: {
          color: chartColors.textDark(this.theme),
          font: {
            size: 11,
          },
          padding: 4,
          precision: 2,
        },
      };
    },

    getYAxisTicksCallback(chart) {
      // eslint-disable-next-line
      const self = this;

      // SEE: https://stackoverflow.com/questions/17369098/simplest-way-of-getting-the-number-of-decimals-in-a-number-in-javascript
      const countDecimals = (value) => {
        if ((value % 1) !== 0) {
          return value.toString().split('.')[1].length;
        }

        return 0;
      };

      return (value, index, ticks) => {
        // Ensure that all ticks have the same amount of decimal places by finding the maximum
        // of decimal places and formatting all values accordingly.
        const maxDecimals = Math.max(...ticks.map((tick) => countDecimals(tick.value)));

        if (chart.variable === 'slr') {
          return formatSlr(value);
        }

        // Hide label on top tick of wind chart so wind gust icons don't overlap label
        if (chart.variable === 'wind_speed' && self.hasWindDir && index === ticks.length - 1) {
          return '';
        }

        if (value === 0) {
          return '0';
        }

        return value.toFixed(maxDecimals);
      };
    },

    handleResize(chart) {
      /* eslint no-param-reassign: off */
      if (this.charts.findIndex(({ id }) => this[id] === chart) === -1) {
        // The resize event handler may be called immediately before the chart instance has
        // been saved to the component when first created or when the units are changed. In
        // either case, if the chart instance passed to the resize event handler is not the
        // same as that saved to the component, then there's no need to handle the resize
        // event!
        return;
      }

      chart.config.data.labels = this.getLabels();
      chart.update('none');
    },

    loadWindDirImage() {
      return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = () => resolve(null);
        image.crossOrigin = 'Anonymous';
        image.src = this.theme === 'dark'
          ? 'https://blizzard.opensnow.com/icons/custom/wind-dir-arrow-dark.svg'
          : 'https://blizzard.opensnow.com/icons/custom/wind-dir-arrow-light.svg';
      });
    },

    renderCharts() {
      this.charts.forEach((chart) => {
        if (chart.chart_type === 'line') {
          this.renderLineChart(chart);
        }
        else if (chart.chart_type === 'bar') {
          this.renderBarChart(chart);
        }
      });
    },

    renderBarChart(chart) {
      const config = this.getConfig(chart);

      config.data.datasets.push(
        this.getDataset(chart.variable, chartColors.saturatedBlue, chart.chart_type),
      );
      config.options.plugins.tooltip.callbacks.label = this.getTooltipLabel(chart);
      config.options.scales.y.beginAtZero = true;
      config.options.scales.y.ticks.callback = this.getYAxisTicksCallback(chart);

      if (chart.variable === 'precip_snow') {
        this.updateConfigForPrecipSnow(chart, config);
      }

      this.createChart(chart, config);
    },

    renderLineChart(chart) {
      const config = this.getConfig(chart);

      config.data.datasets.push(this.getDataset(chart.variable));

      config.options.plugins.tooltip.callbacks.label = this.getTooltipLabel(chart);
      config.options.scales.y.ticks.callback = this.getYAxisTicksCallback(chart);

      if (chart.variable === 'precip_snow_sum') {
        this.updateConfigForPrecipSnowSumChart(chart, config);
      }
      else if (chart.variable === 'temp') {
        this.updateDataForReportInterval(chart.variable, config.data.datasets[0].data);
        this.updateConfigForTempChart(chart, config);
      }
      else if (chart.variable === 'wind_speed') {
        this.updateConfigForWindSpeedChart(chart, config);
        this.updateDataForReportInterval('wind_speed', config.data.datasets[0].data);

        if (this.hasWindGustSpeed) {
          this.updateDataForReportInterval('wind_gust_speed', config.data.datasets[1].data);
        }
      }

      this.createChart(chart, config);
    },

    updateConfigForPrecipSnow(chart, config) {
      // eslint-disable-next-line
      const self = this;

      const getBarColor = (context) => {
        const { dataIndex } = context;
        const period = self.observation[dataIndex];

        if (self.hasMix(period)) {
          return self.snowObservationAlertMixLevel.color_foreground;
        }

        if (self.hasPowder(period)) {
          return self.snowObservationAlertPowderLevel.color_foreground;
        }

        if (self.hasSnow(period)) {
          return self.snowObservationAlertSnowLevel.color_foreground;
        }

        return chartColors.textRegular(self.theme);
      };

      config.data.datasets[0].backgroundColor = getBarColor;
      config.options.plugins.tooltip.filter = ({ raw }) => (typeof raw === 'number' && raw > 0);
    },

    updateConfigForPrecipSnowSumChart(chart, config) {
      config.options.elements.line.spanGaps = true;
      config.options.scales.y.beginAtZero = true;
    },

    updateConfigForTempChart(chart, config) {
      const maxTemp = getMaxValue(config.data.datasets[0].data.map(({ y }) => y));
      const minTemp = getMinValue(config.data.datasets[0].data.map(({ y }) => y));

      if ((this.units === 'imperial' && maxTemp > 32 && minTemp < 32)
        || (this.units === 'metric' && maxTemp > 0 && minTemp < 0)) {
        const freezingDataset = this.getDataset('temp');
        const freezing = this.units === 'imperial' ? 32 : 0;

        freezingDataset.label = 'freezing';
        freezingDataset.data.forEach((value, index) => freezingDataset.data[index].y = freezing);
        freezingDataset.borderDash = [2];
        freezingDataset.pointRadius = 0;
        freezingDataset.pointStyle = false;

        config.data.datasets.push(freezingDataset);
        // Don't display tool tip for the freezing dataset
        config.options.plugins.tooltip.filter = ({ datasetIndex }) => datasetIndex === 0;
      }
    },

    updateConfigForWindSpeedChart(chart, config) {
      if (this.hasWindGustSpeed) {
        const datasetWindGustSpeed = this.getDataset('wind_gust_speed', chartColors.saturatedRed);
        const values = [...config.data.datasets[0].data, ...datasetWindGustSpeed.data];
        const suggestedMax = getMaxValue(values);
        const suggestedMin = getMinValue(values);

        config.data.datasets.push(datasetWindGustSpeed);
        config.options.scales.y.suggestedMax = suggestedMax;
        config.options.scales.y.suggestedMin = suggestedMin;
      }

      if (this.hasWindDir && this.windDirImage) {
        const height = isScreenLg() ? 12 : 8;
        const width = isScreenLg() ? 10 : 6.75;

        // eslint-disable-next-line
        const self = this;

        config.plugins = [{
          id: 'wind-dir-icon',
          afterDraw(chart) {
            const { chartArea, ctx } = chart;
            const datasetMeta = chart.getDatasetMeta(0);

            datasetMeta.data.forEach((point, index) => {
              // Only display wind direction arrow above rendered points
              if (point.options.pointStyle === 'circle') {
                // const { wind_dir, wind_dir_label } = self.observation[index];
                const { wind_dir } = self.observation[index];
                const x = point.x - (width / 2);
                const y = chartArea.top;

                ctx.save();

                // Rotate the canvas so the wind direction icon points in the direction the wind is
                // blowing towards.
                // SEE: https://stackoverflow.com/questions/3793397/html5-canvas-drawimage-with-at-an-angle

                ctx.translate(x, y);
                ctx.rotate((180 + wind_dir) * Math.PI / 180);

                ctx.drawImage(
                  self.windDirImage,
                  (-1 * width) / 2,
                  (-1 * height) / 2,
                  width,
                  height,
                );

                // DEBUG
                // console.log(`Rendered wind direction icon for ${wind_dir_label} (${wind_dir}) at (${x}, ${y}) canvas rotated ${180 + wind_dir}`);

                ctx.restore();
              }
            });
          },
        }];
      }
    },

    /**
     * If the report interval for the variable is greater than the minimum report interval
     * for all variables, and the observation at the next report interval is not null, i.e. it is
     * not missing, then insert interpolated values between data points so that the line chart can
     * be connected. This is a manual implementation of the existing Chart.js spanGaps property
     * which unfortunately just doesn't seem to work in the Weather Station Charts implementation
     * because labels and point styles are manually generated to make charts appear consistent when
     * different variables are reported at different times.
     *
     * This issue was noticed at the Peter Sinks Weather station temp and wind are reported every
     * hour while snow variables are reported (estimated) every 15 minutes.
     *
     * @see https://opensnow.com/weather-stations/psink-peter-sinks-26378?hours=48&view=charts
     */
    updateDataForReportInterval(variable, data) {
      const reportIntervalForVariable = this.getReportInterval(variable);

      if (reportIntervalForVariable > this.reportInterval) {
        const indexOfFirstObservation = data.findIndex((datapoint) => datapoint.y !== null);
        const gapsBetweenObservations = reportIntervalForVariable / this.reportInterval;

        console.log(`WeatherStationCharts.updateDataForReportInterval(): The ${reportIntervalForVariable} min report interval for the ${variable} variable is greater than the ${this.reportInterval} minimum report interval. Values between data points will be interpolated to span gaps.`);

        let index = indexOfFirstObservation;

        while (index < data.length) {
          const indexOfNextObservation = index + gapsBetweenObservations;

          if (data[index].y !== null
            && indexOfNextObservation < data.length
            && data[indexOfNextObservation].y !== null) {
            // Interpolate y values of data points from i to indexOfNextObservation if both
            // the first and last data point have values.
            //
            // 1. Solve for the slope (m) between the two points
            // 2. Solve for the y-intercept using the slope and one point
            // 3. Set the y values of the points from i to indexOfNextObservation
            //
            // Otherwise, if either the first or last next expected data point have no values then
            // do not "span the gaps" so that missing data is visible in the line chart
            //
            // SEE: https://content.byui.edu/file/b8b83119-9acc-4a7b-bc84-efacf9043998/1/Math-2-11-2.html

            const x1 = data[index].x;
            const x2 = data[indexOfNextObservation].x;
            const y1 = data[index].y;
            const y2 = data[indexOfNextObservation].y;

            const m = (y2 - y1) / (x2 - x1);
            const b = y1 - (m * x1);

            for (let j = 1; j < gapsBetweenObservations; j += 1) {
              // Only set the y value of the data point if it's null, just in case!
              if (data[index + j].y === null) {
                data[index + j].y = (m * data[index + j].x) + b;
              }
            }

            index += gapsBetweenObservations;
          }
          else {
            // Find the next data point with data past the current index. If one is not found, then
            // break out of the loop, there is no more data to process!
            let foundNextObservation = false;

            for (let j = index + 1; j < data.length; j += 1) {
              if (data[j].y !== null) {
                index = j;
                foundNextObservation = true;
                break;
              }
            }

            if (!foundNextObservation) {
              break;
            }
          }
        }
      }
    },
  },
};
</script>

<style module>
.chart {
  height: calc(8rem - 1.5rem);
}

@media (min-width: 992px) {
  .chart {
    height: calc(11rem - 1.5rem);
  }
}
</style>
