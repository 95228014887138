/* eslint max-len: off */

const defaultDescription = 'Your trusted source for the most accurate weather forecast, snow report, high-resolution weather maps, and ski conditions information.';

const sanitizeDescription = (description) => {
  let cleanDescription = description;

  if (cleanDescription) {
    // Remove any HTML tags in the description in case the content was used.
    // SEE: https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
    cleanDescription = description.replace(/(<([^>]+)>)/gi, '');

    // Ellpisize the description at the last word boundary if longer than 128 characters
    // SEE: https://stackoverflow.com/a/1199420
    if (cleanDescription?.length > 128) {
      cleanDescription = cleanDescription.slice(0, 125);
      cleanDescription = cleanDescription.slice(0, cleanDescription.lastIndexOf(' '));
      cleanDescription = `${cleanDescription}...`;
    }
  }

  return cleanDescription;
};

const getCountryName = (params) => {
  const { compare } = params || {};
  const { compareId, countries } = compare || {};
  const country = countries?.find(({ code }) => String(code).toLowerCase() === String(compareId).toLowerCase());
  return country?.name || '';
};

const getLocationName = (params) => {
  const { location } = params || {};
  return location?.name || '';
};

const getNewsArticleDescription = (params) => {
  let description = '';

  if (params?.article?.summary) {
    description = params.article.summary;
  }
  else if (params?.article?.content) {
    description = params.article.content;
  }

  return sanitizeDescription(description) || defaultDescription;
};

const getNewsArticleTitle = (params) => params?.article?.title || 'News';

const getDailySnowPostTitle = (params) => {
  const { article, includeContent } = params || {};

  const title = `${article.name} Daily Snow Forecast`;

  if (includeContent) {
    return `${article.post.title} | ${title}`;
  }

  return title;
};

const getDailySnowPostDescription = (params) => {
  const { article, includeContent } = params || {};

  if (includeContent) {
    let description = '';

    if (article.post.summary) {
      description = article.post.summary;
    }

    return sanitizeDescription(description) || defaultDescription;
  }

  return `The ${article.name} Daily Snow provides the best snow forecast, written by a local ${article.name} forecaster. Find snow conditions at ski resorts in ${article.name}.`;
};

const getRegionName = (params) => {
  const { compare } = params || {};
  const { compareId, regions } = compare || {};
  const region = regions?.find(({ slug }) => String(slug).toLowerCase() === String(compareId).toLowerCase());
  return region?.name || '';
};

const getSeasonPassName = (params) => {
  /* eslint camelcase: off */
  const { compare } = params || {};
  const { compareId, season_passes } = compare || {};
  const seasonPass = season_passes?.find(({ slug }) => String(slug).toLowerCase() === String(compareId).toLowerCase());
  return seasonPass?.name || '';
};

const getStateName = (params) => {
  const { compare } = params || {};
  const { compareId, states } = compare || {};
  const state = states?.find(({ code }) => String(code).toLowerCase() === String(compareId).toLowerCase());
  return state?.name || '';
};

const getWeatherStationName = (params) => {
  const { weatherStation } = params || {};
  return weatherStation?.name || '';
};

const pages = [
  {
    screen: 'OpenSnow.com',
    path: /^\/$/,
    title: () => 'OpenSnow: Weather Forecasts & Snow Reports',
    description: () => 'Your trusted source for the most accurate weather forecast, snow report, high-resolution weather maps, and ski conditions information.',
  },
  {
    screen: 'Favorites Map',
    path: /\/user\/favorites\/map/,
    title: () => 'Favorites Map',
    description: () => 'View your favorite locations for the best weather, top snow forecast, deepest snow reports, snow history, and more.',
  },
  {
    screen: 'Favorites List',
    path: /\/user\/favorites/,
    title: () => 'Favorites',
    description: () => 'Compare your favorite locations for the best weather, top snow forecast, deepest snow reports, snow history, and more.',
  },
  {
    screen: 'Maps > Current US Radar',
    path: /\/map\/(.+)\/radar(\/|$)/,
    title: () => 'Current US Radar Map',
    description: () => 'Precipitation detected over the United States during the past two hours to help track incoming and ongoing storms.',
  },
  {
    screen: 'Maps > Current Global Radar',
    path: /\/map\/(.+)\/radar-global-p(\/|$)/,
    title: () => 'Current Global Radar Map',
    description: () => 'Precipitation detected globally during the past two hours to help track incoming and ongoing storms.',
  },
  {
    screen: 'Maps > Current Japan Radar',
    path: /\/map\/(.+)\/radar-japan(\/|$)/,
    title: () => 'Current Japan Radar Map',
    description: () => 'Precipitation detected over Japan during the past three hours to help track incoming and ongoing storms.',
  },
  {
    screen: 'Maps > Forecast US Radar',
    path: /\/map\/(.+)\/forecast-radar/,
    title: () => 'Forecast US Radar Map',
    description: () => 'Precipitation forecast over the United States for the next two days to help track incoming and ongoing storms.',
  },
  {
    screen: 'Maps > Temperature',
    path: /\/map\/(.+)\/temperature/,
    title: () => 'Temperature Forecast Map',
    description: () => 'Temperature forecast for the next two days over the United States.',
  },
  {
    screen: 'Maps > Cloud Cover',
    path: /\/map\/(.+)\/cloud-cover/,
    title: () => 'Cloud Cover Forecast Map',
    description: () => 'Cloud cover forecast for the next two days over the United States.',
  },
  {
    screen: 'Maps > Weather Stations',
    path: /\/map\/(.+)\/weather-stations/,
    title: () => 'Weather Stations Map',
    description: () => '50,000+ global weather stations with hourly temperatures, snowfall, relative humidity, wind speeds, and more.',
  },
  {
    screen: 'Maps > Wind Gust',
    path: /\/map\/(.+)\/wind-gust/,
    title: () => 'Wind Gust Forecast Map',
    description: () => 'Wind gust forecast for the next two days over the United States.',
  },
  {
    screen: 'Maps > Hail Size',
    path: /\/map\/(.+)\/hail-size/,
    title: () => 'Hail Size Map',
    description: () => 'Model-estimated hail size over the past two hours for an area in the United States.',
  },
  {
    screen: 'Maps > Land Ownership',
    path: /\/map\/(.+)\/parcels/,
    title: () => 'Land Ownership Map',
    description: () => 'Private land ownership map and private property lines for any area in the United States.',
  },
  {
    screen: 'Maps > Public Lands',
    path: /\/map\/(.+)\/public-lands/,
    title: () => 'Public Lands Map',
    description: () => 'Federal, state, and local land ownership map for any area in the United States.',
  },
  {
    screen: 'Maps > Lightning Risk',
    path: /\/map\/(.+)\/lightning-risk/,
    title: () => 'Lightning Risk Map',
    description: () => 'Recent lightning strikes and the probability that lightning will strike in the next 60 minutes for any area in the United States.',
  },
  {
    screen: 'Maps > Air Quality Forecast',
    path: /\/map\/(.+)\/air-quality-forecast/,
    title: () => 'Air Quality Forecast Map',
    description: () => 'Global air quality forecast for the next five days.',
  },
  {
    screen: 'Maps > Air Quality Real-Time',
    path: /\/map\/(.+)\/air-quality/,
    title: () => 'Air Quality Map',
    description: () => 'Current air quality from PurpleAir sensors. Shown as the 10-minute average for the past 24 hours.',
  },
  {
    screen: 'Maps > Smoke Hi-Res (surface)',
    path: /\/map\/(.+)\/smoke-surface-hr/,
    title: () => 'Hi-Res Wildfire Smoke Forecast Map (Surface)',
    description: () => 'High-resolution smoke forecast near the ground where it can impact breathing for the next two days.',
  },
  {
    screen: 'Maps > Smoke (surface)',
    path: /\/map\/(.+)\/smoke-surface/,
    title: () => 'Wildfire Smoke Forecast Map (Surface)',
    description: () => 'Smoke forecast near the ground where it can impact breathing for the next two days.',
  },
  {
    screen: 'Maps > Smoke Hi-Res (sky)',
    path: /\/map\/(.+)\/smoke-sky-hr/,
    title: () => 'Hi-Res Wildfire Smoke Forecast Map (Sky)',
    description: () => 'High-resolution smoke forecast in the sky where it can impact visibility for the next two days.',
  },
  {
    screen: 'Maps > Smoke (sky)',
    path: /\/map\/(.+)\/smoke-sky/,
    title: () => 'Wildfire Smoke Forecast Map (Sky)',
    description: () => 'Smoke forecast in the sky where it can impact visibility for the next two days.',
  },
  {
    screen: 'Maps > Active Fires',
    path: /\/map\/(.+)\/wildfire/,
    title: () => 'Wildfire Hotspot & Perimeter Map',
    description: () => 'Current active fires map, including fire perimeter, containment, and hotspot details.',
  },
  {
    screen: 'Maps > Avalanche Forecast',
    path: /\/map\/(.+)\/avalanche-forecast/,
    title: () => 'Avalanche Forecast Map',
    description: () => 'Daily avalanche forecasts from United States and Canada avalanche centers.',
  },
  {
    screen: 'Maps > Snow Depth',
    path: /\/map\/(.+)\/snow-depth/,
    title: () => 'Snow Depth Map',
    description: () => 'Model-estimated snow depth over the United States.',
  },
  {
    screen: 'Maps > Snowfall (24 Hours)',
    path: /\/map\/(.+)\/snowfall-24-hours/,
    title: () => '24-Hour Snowfall Map',
    description: () => 'Model-estimated snowfall for the past 24 hours over the United States.',
  },
  {
    screen: 'Maps > Snowfall (Season)',
    path: /\/map\/(.+)\/snowfall-season/,
    title: () => 'Season Snowfall',
    description: () => 'Model-estimated snowfall since October 1 over the United States.',
  },
  {
    screen: 'Maps > Forecast Total Snowfall',
    path: /\/map\/(.+)\/c9-snowfall-total/,
    title: () => 'Forecast Total Snowfall Map',
    description: () => 'Forecasted total snowfall for the next 10 days from the OpenSnow blend of weather models over the United States and Canada.',
  },
  {
    screen: 'Maps > Forecast 6-Hour Snowfall',
    path: /\/map\/(.+)\/c9-snowfall-6h/,
    title: () => 'Forecast 6-Hour Snowfall Map',
    description: () => 'Forecasted 6-hour snowfall for the next 10 days from the OpenSnow blend of weather models over the United States and Canada.',
  },
  {
    screen: 'Maps > Forecast 12-Hour Snowfall',
    path: /\/map\/(.+)\/c9-snowfall-12h/,
    title: () => 'Forecast 12-Hour Snowfall Map',
    description: () => 'Forecasted 12-hour snowfall for the next 10 days from the OpenSnow blend of weather models over the United States and Canada.',
  },
  {
    screen: 'Maps > Forecast 24-Hour Snowfall',
    path: /\/map\/(.+)\/c9-snowfall-24h/,
    title: () => 'Forecast 24-Hour Snowfall Map',
    description: () => 'Forecasted 24-hour snowfall for the next 10 days from the OpenSnow blend of weather models over the United States and Canada.',
  },
  {
    screen: 'Maps',
    path: /^\/map/,
    title: () => 'Maps',
    description: () => 'View high-resolution 3D maps, including current radar, snow depth, air quality, active fires, wildfire smoke, and land ownership.',
  },
  {
    screen: 'Daily Snow > Forecast > Post',
    path: /\/dailysnow\/(.+)\/post\/(.+)/,
    title: (params) => getDailySnowPostTitle({ ...params, ...{ includeContent: true } }),
    description: (params) => getDailySnowPostDescription({ ...params, ...{ includeContent: true } }),
  },
  {
    screen: 'Daily Snow > Forecast',
    path: /\/dailysnow\/(.+)/,
    title: (params) => getDailySnowPostTitle({ ...params, ...{ includeContent: false } }),
    description: (params) => getDailySnowPostDescription({ ...params, ...{ includeContent: false } }),
  },
  {
    screen: 'Daily Snow',
    path: /\/dailysnow/,
    title: () => 'Daily Snow Forecasts',
    description: () => 'Read daily snow forecasts and ski reports from our team of local weather forecasters.',
  },
  {
    screen: 'Region > Weather',
    path: /\/explore\/regions\/(.+)\/weather/,
    title: (params) => `${getRegionName(params)} Weather Forecast`,
    description: (params) => `${getRegionName(params)} 10-day weather forecast, with current conditions, air quality, lightning potential, and the hourly forecast for the next 5 days.`,
  },
  {
    screen: 'Region > Snow Summary',
    path: /\/explore\/regions\/(.+)\/snow-summary/,
    title: (params) => `${getRegionName(params)} Snow Report & Forecast`,
    description: (params) => `${getRegionName(params)} 24-hour snow report and 10-day snow forecast summary.`,
  },
  {
    screen: 'Region > Snow Forecast',
    path: /\/explore\/regions\/(.+)\/snow-forecast/,
    title: (params) => `${getRegionName(params)} Snow Forecast`,
    description: (params) => `${getRegionName(params)} snow forecast for each 12-hour day and night period over the next 10 days.`,
  },
  {
    screen: 'Region > Snow History',
    path: /\/explore\/regions\/(.+)\/snow-history/,
    title: (params) => `${getRegionName(params)} Snow History`,
    description: (params) => `${getRegionName(params)} snow history, sorted by the past 24 hours, previous 5 days, and previous 10 days.`,
  },
  {
    screen: 'Region > All Cams',
    path: /\/explore\/regions\/(.+)\/all-cams/,
    title: (params) => `${getRegionName(params)} Webcams`,
    description: (params) => `${getRegionName(params)} live mountain cams and highway webcams, sorted by current conditions, snow reports, top snow forecasts, and more.`,
  },
  {
    screen: 'Region > Snow Report',
    path: /\/explore\/regions\/(.+)\/snow-report/,
    title: (params) => `${getRegionName(params)} Snow Report`,
    description: (params) => `${getRegionName(params)} 24-hour snow report and base depth, season snowfall, and base depth vs average.`,
  },
  {
    screen: 'Region > Temperature',
    path: /\/explore\/regions\/(.+)\/temperature/,
    title: (params) => `${getRegionName(params)} Current Temperature & Forecast`,
    description: (params) => `${getRegionName(params)} current conditions and temperature forecast.`,
  },
  {
    screen: 'Region Map',
    path: /\/explore\/regions\/(.+)\/map/,
    title: (params) => `${getRegionName(params)} Snow Report & Forecast Map`,
    description: (params) => `${getRegionName(params)} 24-hour snow report and 10-day snow forecast summary map.`,
  },
  {
    screen: 'Season Pass > Weather',
    path: /\/explore\/season-passes\/(.+)\/weather/,
    title: (params) => `${getSeasonPassName(params)} Weather Forecast`,
    description: (params) => `${getSeasonPassName(params)} 10-day weather forecast, with current conditions, air quality, lightning potential, and the hourly forecast for the next 5 days.`,
  },
  {
    screen: 'Season Pass > Snow Summary',
    path: /\/explore\/season-passes\/(.+)\/snow-summary/,
    title: (params) => `${getSeasonPassName(params)} Snow Report & Forecast`,
    description: (params) => `${getSeasonPassName(params)} 24-hour snow report and 10-day snow forecast summary.`,
  },
  {
    screen: 'Season Pass > Snow Forecast',
    path: /\/explore\/season-passes\/(.+)\/snow-forecast/,
    title: (params) => `${getSeasonPassName(params)} Snow Forecast`,
    description: (params) => `${getSeasonPassName(params)} snow forecast for each 12-hour day and night period over the next 10 days.`,
  },
  {
    screen: 'Season Pass > Snow History',
    path: /\/explore\/season-passes\/(.+)\/snow-history/,
    title: (params) => `${getSeasonPassName(params)} Snow History`,
    description: (params) => `${getSeasonPassName(params)} snow history, sorted by the past 24 hours, previous 5 days, and previous 10 days.`,
  },
  {
    screen: 'Season Pass > All Cams',
    path: /\/explore\/season-passes\/(.+)\/all-cams/,
    title: (params) => `${getSeasonPassName(params)} Webcams`,
    description: (params) => `${getSeasonPassName(params)} live mountain cams and highway webcams, sorted by current conditions, snow reports, top snow forecasts, and more.`,
  },
  {
    screen: 'Season Pass > Snow Report',
    path: /\/explore\/season-passes\/(.+)\/snow-report/,
    title: (params) => `${getSeasonPassName(params)} Snow Report`,
    description: (params) => `${getSeasonPassName(params)} 24-hour snow report and base depth, season snowfall, and base depth vs average.`,
  },
  {
    screen: 'Season Pass > Temperature',
    path: /\/explore\/season-passes\/(.+)\/temperature/,
    title: (params) => `${getSeasonPassName(params)} Current Temperature & Forecast`,
    description: (params) => `${getSeasonPassName(params)} current conditions and temperature forecast.`,
  },
  {
    screen: 'Season Pass Map',
    path: /\/explore\/season-passes\/(.+)\/map/,
    title: (params) => `${getSeasonPassName(params)} Snow Report & Forecast Map`,
    description: (params) => `${getSeasonPassName(params)} 24-hour snow report and 10-day snow forecast summary map.`,
  },
  {
    screen: 'State > Weather',
    path: /\/explore\/states\/(.+)\/weather/,
    title: (params) => `${getStateName(params)} Weather Forecast`,
    description: (params) => `${getStateName(params)} 10-day weather forecast, with current conditions, air quality, lightning potential, and the hourly forecast for the next 5 days.`,
  },
  {
    screen: 'State > Snow Summary',
    path: /\/explore\/states\/(.+)\/snow-summary/,
    title: (params) => `${getStateName(params)} Snow Report & Forecast`,
    description: (params) => `${getStateName(params)} 24-hour snow report and 10-day snow forecast summary.`,
  },
  {
    screen: 'State > Snow Forecast',
    path: /\/explore\/states\/(.+)\/snow-forecast/,
    title: (params) => `${getStateName(params)} Snow Forecast`,
    description: (params) => `${getStateName(params)} snow forecast for each 12-hour day and night period over the next 10 days.`,
  },
  {
    screen: 'State > Snow History',
    path: /\/explore\/states\/(.+)\/snow-history/,
    title: (params) => `${getStateName(params)} Snow History`,
    description: (params) => `${getStateName(params)} snow history, sorted by the past 24 hours, previous 5 days, and previous 10 days.`,
  },
  {
    screen: 'State > All Cams',
    path: /\/explore\/states\/(.+)\/all-cams/,
    title: (params) => `${getStateName(params)} Webcams`,
    description: (params) => `${getStateName(params)} live mountain cams and highway webcams, sorted by current conditions, snow reports, top snow forecasts, and more.`,
  },
  {
    screen: 'State > Snow Report',
    path: /\/explore\/states\/(.+)\/snow-report/,
    title: (params) => `${getStateName(params)} Snow Report`,
    description: (params) => `${getStateName(params)} 24-hour snow report and base depth, season snowfall, and base depth vs average.`,
  },
  {
    screen: 'State > Temperature',
    path: /\/explore\/states\/(.+)\/temperature/,
    title: (params) => `${getStateName(params)} Current Temperature & Forecast`,
    description: (params) => `${getStateName(params)} current conditions and temperature forecast.`,
  },
  {
    screen: 'State Map',
    path: /\/explore\/states\/(.+)\/map/,
    title: (params) => `${getStateName(params)} Snow Report & Forecast Map`,
    description: (params) => `${getStateName(params)} 24-hour snow report and 10-day snow forecast summary map.`,
  },
  {
    screen: 'Country > Weather',
    path: /\/explore\/countries\/(.+)\/weather/,
    title: (params) => `${getCountryName(params)} Weather Forecast`,
    description: (params) => `${getCountryName(params)} 10-day weather forecast, with current conditions, air quality, lightning potential, and the hourly forecast for the next 5 days.`,
  },
  {
    screen: 'Country > Snow Summary',
    path: /\/explore\/countries\/(.+)\/snow-summary/,
    title: (params) => `${getCountryName(params)} Snow Report & Forecast`,
    description: (params) => `${getCountryName(params)} 24-hour snow report and 10-day snow forecast summary.`,
  },
  {
    screen: 'Country > Snow Forecast',
    path: /\/explore\/countries\/(.+)\/snow-forecast/,
    title: (params) => `${getCountryName(params)} Snow Forecast`,
    description: (params) => `${getCountryName(params)} snow forecast for each 12-hour day and night period over the next 10 days.`,
  },
  {
    screen: 'Country > Snow History',
    path: /\/explore\/countries\/(.+)\/snow-history/,
    title: (params) => `${getCountryName(params)} Snow History`,
    description: (params) => `${getCountryName(params)} snow history, sorted by the past 24 hours, previous 5 days, and previous 10 days.`,
  },
  {
    screen: 'Country > All Cams',
    path: /\/explore\/countries\/(.+)\/all-cams/,
    title: (params) => `${getCountryName(params)} Webcams`,
    description: (params) => `${getCountryName(params)} live mountain cams and highway webcams, sorted by current conditions, snow reports, top snow forecasts, and more.`,
  },
  {
    screen: 'Country > Snow Report',
    path: /\/explore\/countries\/(.+)\/snow-report/,
    title: (params) => `${getCountryName(params)} Snow Report`,
    description: (params) => `${getCountryName(params)} 24-hour snow report and base depth, season snowfall, and base depth vs average.`,
  },
  {
    screen: 'Country > Temperature',
    path: /\/explore\/countries\/(.+)\/temperature/,
    title: (params) => `${getCountryName(params)} Current Temperature & Forecast`,
    description: (params) => `${getCountryName(params)} current conditions and temperature forecast.`,
  },
  {
    screen: 'Country Map',
    path: /\/explore\/countries\/(.+)\/map/,
    title: (params) => `${getCountryName(params)} Snow Report & Forecast Map`,
    description: (params) => `${getCountryName(params)} 24-hour snow report and 10-day snow forecast summary map.`,
  },
  {
    screen: 'Powder Finder Map',
    path: /\/explore\/powder\/map/,
    title: () => 'Powder Finder Map',
    description: () => 'View the top snow forecasts and deepest 24-hour snow reports map for ski resorts sorted by season pass, region, state, prefecture, and country.',
  },
  {
    screen: 'Powder Finder List',
    path: /\/explore\/powder/,
    title: () => 'Powder Finder',
    description: () => 'View the top snow forecasts and deepest 24-hour snow reports for ski resorts sorted by season pass, region, state, prefecture, and country.',
  },
  {
    screen: 'Snow Stake Cams',
    path: /\/explore\/snowstakes/,
    title: () => 'Snow Stake Cams',
    description: () => 'View snow stake cams for the top ski resort snow forecasts and deepest 24-hour snow reports around the world.',
  },
  {
    screen: 'Explore',
    path: /\/explore/,
    title: () => 'Explore',
    description: () => 'Top snow forecasts and deepest 24-hour snow reports for ski resorts sorted by season pass, region, state, prefecture, and country.',
  },
  {
    screen: 'Location > Weather Stations',
    path: /\/location\/(.+)\/weather-stations/,
    title: (params) => `${getLocationName(params)} Nearby Weather Stations`,
    description: (params) => `Weather stations located near ${getLocationName(params)}.`,
  },
  {
    screen: 'Location > Weather',
    path: /\/location\/(.+)\/weather/,
    title: (params) => `${getLocationName(params)} Weather Forecast`,
    description: (params) => `${getLocationName(params)} 10-day weather forecast, with current conditions, air quality, lightning potential, and the hourly forecast for the next 5 days.`,
  },
  {
    screen: 'Location > Snow Summary',
    path: /\/location\/(.+)\/snow-summary/,
    title: (params) => `${getLocationName(params)} Snow Forecast`,
    description: (params) => `${getLocationName(params)} snow forecast, with current conditions, historical weather, and detailed weather information for the next 10 days.`,
  },
  {
    screen: 'Location > Snow Report',
    path: /\/location\/(.+)\/snow-report/,
    title: (params) => `${getLocationName(params)} Snow Report`,
    description: (params) => `${getLocationName(params)} 24-hour snow report, base depth, and terrain status. View the latest daily ski report, snowfall history, and season snowfall.`,
  },
  {
    screen: 'Location > Cams',
    path: /\/location\/(.+)\/cams/,
    title: (params) => `${getLocationName(params)} Webcams`,
    description: (params) => `${getLocationName(params)} live mountain cams, highway webcams, and snow stake cam.`,
  },
  {
    screen: 'Location > Daily Snows',
    path: /\/location\/(.+)\/daily-snows/,
    title: (params) => `${getLocationName(params)} Daily Snow Forecasts`,
    description: (params) => `${getLocationName(params)} snow forecast and ski conditions report, updated daily by expert forecasters.`,
  },
  {
    screen: 'Location > Trail Maps',
    path: /\/location\/(.+)\/trail-maps/,
    title: (params) => `${getLocationName(params)} Trail Maps`,
    description: (params) => `${getLocationName(params)} trail map. Download for offline use to navigate the ski resort.`,
  },
  {
    screen: 'Location > Avalanche Forecast',
    path: /\/location\/(.+)\/avalanche-forecast/,
    title: (params) => `${getLocationName(params)} Avalanche Forecast`,
    description: (params) => `${getLocationName(params)} avalanche forecast and conditions information. Daily backcountry avalanche forecast from a local avalanche center.`,
  },
  {
    screen: 'Location > Info',
    path: /\/location\/(.+)\/info/,
    title: (params) => `${getLocationName(params)} Info`,
    description: (params) => `${getLocationName(params)} operating hours and terrain status. View social media links, driving directions, and more.`,
  },
  {
    screen: 'My Location',
    path: /\/my-location/,
    title: () => 'My Location',
    description: () => 'Your Location\'s 10-day weather forecast, with current conditions, air quality, lightning potential, and the hourly forecast for the next 5 days.',
  },
  {
    screen: 'News > Post',
    path: /\/news\/post/,
    title: (params) => getNewsArticleTitle(params),
    description: (params) => getNewsArticleDescription(params),
  },
  {
    screen: 'News > Company Updates',
    path: /\/news\/company-updates/,
    title: () => 'Company Updates',
    description: () => 'View the latest feature announcements and company updates from the OpenSnow team.',
  },
  {
    screen: 'News > Video & Podcasts',
    path: /\/news\/videos-and-podcasts/,
    title: () => 'Ski Videos & Podcasts',
    description: () => 'Watch ski videos and listen to the latest ski industry podcasts.',
  },
  {
    screen: 'News > Snow & Weather Science',
    path: /\/news\/snow-and-weather-science/,
    title: () => 'Snow & Weather Science',
    description: () => 'Learn about snow and weather science from the team of expert mountain meteorologists at OpenSnow.',
  },
  {
    screen: 'News > Snowpack Updates',
    path: /\/news\/snowpack-updates/,
    title: () => 'Snowpack Updates',
    description: () => 'View recent snowpack updates and snowfall anaylsis, updated for ski regions around the world.',
  },
  {
    screen: 'News > Trip Reports',
    path: /\/news\/trip-reports/,
    title: () => 'Trip Reports',
    description: () => 'View trip reports from ski resorts and backcountry locations around the world.',
  },
  {
    screen: 'News > Resort Guides',
    path: /\/news\/resort-guides/,
    title: () => 'Ski Resort Guides',
    description: () => 'View resort guides written by local experts from ski resorts around the world.',
  },
  {
    screen: 'News > Other',
    path: /\/news\/other/,
    title: () => 'News',
    description: () => 'View the latest mountain weather and industry news, including ski videos and podcasts, snow and weather science, snowpack updates, and more.',
  },
  {
    screen: 'News ',
    path: /\/news/,
    title: () => 'News',
    description: () => 'View the latest mountain weather and industry news, including ski videos and podcasts, snow and weather science, snowpack updates, and more.',
  },
  {
    screen: 'Settings > Account',
    path: '/user/settings/account',
    title: () => 'Your Account',
    description: () => 'View your OpenSnow All-Access subscription status, manage your subscription, and more.',
  },
  {
    screen: 'Settings > Your Favorites',
    path: /\/user\/settings\/favorites/,
    title: () => 'Your Favorites',
    description: () => 'View your OpenSnow All-Access subscription status, edit favorite locations, update snow alerts, and more.',
  },
  {
    screen: 'Settings > Your Notifications',
    path: '/user/settings/notifications',
    title: () => 'Your Notifications',
    description: () => 'View your OpenSnow All-Access notification settings, edit marketing preferences, update snow alerts, and more.',
  },
  {
    screen: 'Settings > Privacy',
    path: '/user/settings/privacy',
    title: () => 'Your Privacy',
    description: () => 'View your OpenSnow All-Access privacy settings, manage your subscription, and more.',
  },
  {
    screen: 'Start Free Trial',
    path: /\/user\/register/,
    title: () => 'Start Free Trial',
    description: () => 'Start your free trial to OpenSnow All-Access, no credit card required.',
  },
  {
    screen: 'Buy',
    path: /\/buy/,
    title: () => 'Purchase',
    description: () => 'Purchase OpenSnow All-Access to unlock the essential weather tools for every adventure.',
  },
  {
    screen: 'Buy Now',
    path: /\/user\/buy-now/,
    title: () => 'Buy All-Access',
    description: () => 'Buy OpenSnow All-Access to unlock the essential weather tools for every adventure.',
  },
  {
    screen: 'Log In',
    path: /\/user\/login/,
    title: () => 'Log In',
    description: () => 'Log into OpenSnow.com to view the essential weather tools for every adventure.',
  },
  {
    screen: 'Reset Password',
    path: /\/user\/forgotpassword/,
    title: () => 'Reset Password',
    description: () => 'Reset your OpenSnow password.',
  },
  {
    screen: 'App',
    path: /\/app/,
    title: () => 'OpenSnow App',
    description: () => 'Download the free OpenSnow app for the most accurate snow forecast, snow report, and ski conditions information. The essential app for the mountains.',
  },
  {
    screen: 'All-Access',
    path: /\/allaccess/,
    title: () => 'OpenSnow All-Access',
    description: () => 'The essential weather tools for every adventure, all in one app. Purchase OpenSnow All-Access today.',
  },
  {
    screen: 'About',
    path: /\/about/,
    title: () => 'About',
    description: () => 'OpenSnow is a weather forecasting and conditions information service that provides everything that you need for your next outdoor adventure.',
  },
  {
    screen: 'Team',
    path: '/team',
    title: () => 'Team',
    description: () => 'Learn more about the OpenSnow team, including leadership, engineering, forecasters, and the athlete team.',
  },
  {
    screen: 'Careers',
    path: '/careers',
    title: () => 'Careers',
    description: () => 'We are always interested in talking with engineers specializing in native apps, front and back-end web, API development, and geospatial applications.',
  },
  {
    screen: 'Contact',
    path: '/contact',
    title: () => 'Contact',
    description: () => 'Send an email to hello@opensnow.com to receive help and provide feedback.',
  },
  {
    screen: 'Privacy Policy',
    path: '/privacy',
    title: () => 'Privacy Policy',
    description: () => 'Privacy Policy for OpenSnow.com and the OpenSnow app.',
  },
  {
    screen: 'Terms of Use',
    path: '/terms',
    title: () => 'Terms of Use',
    description: () => 'Terms of Use for OpenSnow.com and the OpenSnow app.',
  },
  {
    screen: 'Partnerships',
    path: '/partnerships',
    title: () => 'Partnership Policy',
    description: () => 'Partnership Policy for OpenSnow.com and the OpenSnow app.',
  },
  {
    screen: 'API',
    path: '/api',
    title: () => 'OpenSnow API',
    description: () => 'The OpenSnow API is not available for general use and is only made available through long-standing partnerships.',
  },
  {
    screen: 'Weather Station History',
    path: /\/weather-stations\/(.+)/,
    title: (params) => `${getWeatherStationName(params)} Weather Station History`,
    description: (params) => `Current conditions, historical charts, and data at the ${getWeatherStationName(params)} weather station.`,
  },
  {
    screen: 'Component Stories',
    path: /\/stories/,
    title: () => 'Component Stories',
    description: () => 'Component stories documenting configuration of common components in OpenSnow.',
  },
];

export const getDescription = (params = {}) => {
  const page = pages.find(({ path }) => params?.path?.match(path));
  return page ? page.description(params) : '';
};

export const getTitle = (params = {}) => {
  const page = pages.find(({ path }) => params?.path?.match(path));
  return page ? `${page.title(params)} | ${params.config.siteName}` : '';
};

/**
 * Create JSON-LD structure for improved search result display of news and daily snow pages
 * @see https://developers.google.com/search/docs/appearance/structured-data/article#structured-data-type-definitions
 * @see https://developers.google.com/search/docs/appearance/structured-data/paywalled-content
 */
export const createPostJsonLd = (params) => {
  const {
    post,
    isFree = true,
  } = params;

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    'headline': post.title,
    'datePublished': post.posted_at,
    'author': [{
      '@type': 'Person',
      'name': post.author.name,
    }],
    'isAccessibleForFree': isFree ? 'True' : 'False',
  };

  if (post.summary) {
    jsonLd.description = post.summary;
  }

  if (post.image_url) {
    jsonLd.image = [post.image_url];
  }

  if (post.updated_at !== post.posted_at) {
    jsonLd.dateModified = post.updated_at;
  }

  return jsonLd;
};
