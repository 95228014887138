<template>
  <client-only>
    <p
      v-if="unableToLoadMap"
      class="tw-h-full tw-flex tw-flex-col tw-justify-center tw-text-center"
    >
      <strong class="tw-font-bold tw-text-lg error-message-color">
        Unable to load map!
      </strong>
      <span class="tw-mt-2 tw-text-sm text-dark-color">
        Your browser might not support WebGL. Learn more
        <a
          class="link-color-brand tw-underline"
          href="https://get.webgl.org/"
        >
          here.
        </a>
      </span>
    </p>
    <div
      v-else
      :class="containerClass"
    >
      <MapCard
        v-if="isMapLoaded"
        :class="mapCardClass"
      />
      <CoverageBanner
        @click="zoomToOverlayExtent"
        @click-secondary="openLayerDrawer"
      />
      <transition
        enter-active-class="fade-enter-transition"
        enter-from-class="fade-out"
        leave-active-class="fade-leave-transition"
        leave-to-class="fade-out"
      >
        <Tooltip
          v-if="isMapLoaded && shouldShowTooltip"
          :class="tooltipClass"
        />
      </transition>
      <div
        ref="mapContainer"
        class="tw-h-full"
      />
      <CompassControl
        v-if="isMapLoaded && canShowCompass"
        :map="map"
      />
      <MapControlGroup
        v-if="isMapLoaded && canShowMapControlGroup"
        :can-view-full-screen="canViewFullScreen"
        :controls-to-show="controlsToShowFinal"
        :map="map"
      />
      <OverlayFieldControl
        v-if="!isMiniMap && shouldShowOverlayFieldControl"
      />
      <ControlContainer
        v-if="isMapLoaded && canShowControlContainer"
        :can-select-base-maps="canSelectBaseMaps"
        :can-select-location-types="canSelectLocationTypes"
        :can-select-overlays="canSelectOverlays"
        class="tw-absolute tw-bottom-0"
      />
      <div class="tw-h-0 tw-w-0 tw-overflow-hidden">
        <component
          :is="overlay"
          v-for="overlay in overlays"
          v-bind="overlayProps[overlay.name]"
          :key="overlay.name"
          ref="overlays"
          :is-map-loaded="isMapLoaded"
          :map="map"
        />
      </div>
    </div>
  </client-only>
</template>

<script>
/* eslint no-console: off, vue/no-reserved-component-names: off */
import { mapActions, mapState } from 'pinia';
import debounce from 'lodash.debounce';
import { parseOpenMountainApiError, replaceHistoryState } from '@@/utils/CommonUtils';
import { useMapStore } from '@@/stores/Map';
import { useMetaStore } from '@@/stores/Meta';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';
import { waitForRef } from '@@/utils/Components/ComponentUtils';
import { bearing360 } from '@@/utils/MapUtils';
import MarkerMixin from '@@/components/Maps/Overlays/Mixins/MarkerMixin';

export default {
  name: 'Map',

  mixins: [
    MarkerMixin,
  ],

  props: {
    baseMap: {
      type: String,
      default: 'terrain',
    },
    bounds: {
      type: [Array, Object],
      default: null,
    },
    canBeFullScreen: {
      type: Boolean,
      default: true,
    },
    canSaveMapState: {
      type: Boolean,
      default: false,
    },
    canSelectBaseMaps: {
      type: Boolean,
      default: false,
    },
    canSelectLocationTypes: {
      type: Boolean,
      default: false,
    },
    canSelectOverlays: {
      type: Boolean,
      default: false,
    },
    canShowForecastAnywhere: {
      type: Boolean,
      default: false,
    },
    canShowLoading: {
      type: Boolean,
      default: true,
    },
    canToggleLocations: {
      type: Boolean,
      default: false,
    },
    canViewFullScreen: {
      type: Boolean,
      default: false,
    },
    center: {
      type: [Array, Object],
      default() { return [-90, 0]; },
    },
    controlsToShow: {
      type: Array,
      default: null,
    },
    currentOverlay: {
      type: String,
      default: null,
    },
    isMiniMap: {
      type: Boolean,
      default: false,
    },
    markerLngLat: {
      type: [Array, Object],
      default: null,
    },
    overlays: {
      type: Array,
      default() { return []; },
    },
    overlayProps: {
      type: Object,
      default() { return {}; },
    },
    padding: {
      type: [Number, Object],
      default: null,
    },
    savedMapState: {
      type: Object,
      default: null,
    },
    shouldReplaceUrl: {
      type: Boolean,
      default: false,
    },
    shouldShowTooltip: {
      type: Boolean,
      default: false,
    },
    shouldShowLocations: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 9,
    },
  },

  emits: [
    'map-loaded',
    'unable-to-load-map',
  ],

  data() {
    return {
      isMapLoaded: false,
      savedMapStateToLoad: null,
      unableToLoadMap: false,
    };
  },

  computed: {
    ...mapState(useMapStore, [
      'currentBaseMap',
      'is3D',
      'savedMapStateKeyName',
      'shouldShowOverlayFieldControl',
      'url',
    ]),

    ...mapState(useMapStore, {
      isFullScreen: (state) => state.ui.isFullScreen,
      mapCard: (state) => state.ui.mapCard,
      mapLocation: (state) => state.ui.mapLocation,
      shouldReset3D: (state) => state.ui.shouldReset3D,
      showAnimationControl: (state) => state.ui.showAnimationControl,
      overlayCoverageBbox: (state) => state.ui.overlayCoverageBbox,
      controlsToShowStore: (state) => state.ui.controlsToShow,
      sources: (state) => state.data.sources,
    }),

    ...mapState(useMetaStore, {
      favoriteListTypes: (state) => state.favorite_list_types,
    }),

    ...mapState(useUserStore, ['isAllAccess']),

    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),

    ...mapState(useUserFavoritesStore, {
      lists: (state) => state.user?.lists,
    }),

    canShowCompass() {
      return this.controlsToShowFinal?.includes('Compass');
    },

    canShowControlContainer() {
      return this.canSelectBaseMaps || this.canSelectLocationTypes || this.canSelectOverlays;
    },

    canShowMapControlGroup() {
      return this.controlsToShowFinal?.length > 0 || this.canViewFullScreen;
    },

    canShowToggle3DControl() {
      return this.controlsToShowFinal?.includes('Toggle3D');
    },

    controlsToShowFinal() {
      return this.controlsToShow ?? this.controlsToShowStore;
    },

    containerClass() {
      const containerClass = [
        'tw-relative',
        'tw-h-full',
        'tw-w-full',
      ];

      if (this.isFullScreen) {
        return containerClass;
      }

      containerClass.push('tw-overflow-hidden');

      if (!this.isMiniMap) {
        containerClass.push('tw-rounded-lg');
      }

      return containerClass;
    },

    mapCardClass() {
      return [
        'tw-absolute tw-z-20 tw-top-0 lg:tw-right-10',
        this.$style.mapCardClass,
      ];
    },

    tooltipClass() {
      return [
        'tw-absolute tw-z-30 tw-bottom-16',
        'tw-w-full',
      ];
    },
  },

  watch: {
    currentBaseMap() {
      if (this.map) {
        this.resetMap(true);
      }
    },

    is3D() {
      if (this.map) {
        this.toggle3D();
      }
    },

    isFullScreen() {
      this.handleResize();
    },

    mapLocation() {
      this.flyToMapLocation();
    },

    units() {
      this.resetMap();
    },

    url() {
      if (this.url && this.shouldReplaceUrl) {
        replaceHistoryState(this.url);
      }
    },
  },

  /**
   * Using Mapbox 3, if the map instance is saved to a reactive property on the component then
   * something Vue does to make the property "reactive" interferes with the rendering of the map
   * causing it to render with nothing more than a yellow background. To work around this, the
   * map instance is saved in a non-reactive property on the component, i.e. the map instance
   * is not saved in the <Map> component's data.
   * @see:
   * - https://github.com/mapbox/mapbox-gl-js/issues/13017
   * - https://stackoverflow.com/questions/45814507/how-to-set-a-component-non-reactive-data-in-vue-2
   */
  created() {
    this.map = null;
  },

  async mounted() {
    await this.$nextTick();

    if (this.canShowLoading) {
      this.$loading.start();
    }

    try {
      console.log('Map.fetch(): Enter');

      await waitForRef(this, 'mapContainer');
      const sources = this.$refs.overlays
        ? this.$refs.overlays.map((overlay) => overlay.source)
        : null;

      await this.fetchMapSourcesMeta({ sources });

      const { canSaveMapState } = this;
      this.setMapUiPropertiesNoSave({ canSaveMapState });

      await this.$nextTick();

      this.init();

      console.log('Map.fetch(): Exit');
    }
    catch (e) {
      const { message } = parseOpenMountainApiError(e);

      this.$toast.open({
        message: message || 'Unable to load map!',
        type: 'error',
      });
      this.$loading.finish();
    }
  },

  beforeUnmount() {
    this.handleBeforeDestroy();
  },

  methods: {
    ...mapActions(useMapStore, [
      'fetchMapSourcesMeta',
      'filterMapState',
      'getMapStateFromUrl',
      'getSavedMapState',
      'resetState',
      'setAvalancheCard',
      'setCurrentBaseMap',
      'setMapCustomLocation',
      'setMapLocation',
      'setMapUiProperties',
      'setMapUiPropertiesNoSave',
      'setIsLayerDrawerOpen',
    ]),

    addMouseEvents() {
      this.map.getCanvas().style.cursor = 'pointer';
      this.map.on('mousedown', this.handleMouseDown);
      this.map.on('moveend', this.handleMoveEnd);
    },

    addResizeHandler() {
      // HACK: Note that the debounced resize handler is called 750ms after the last resize event
      // so that the resize handler in the <Main> component is called first! This is done so that
      // the <Main> component can reset the custom --vh units and resize the page properly _before_
      // the map is resized.
      this.handleResizeListener = debounce(() => this.handleResize(), 750);
      window.addEventListener('resize', this.handleResizeListener);
    },

    flyToMapLocation() {
      if (this.map && this.mapLocation) {
        const { lat, lng } = this.mapLocation;
        const center = [lng, lat];

        // On small screens adjust the center of the map down by 150px so the new map location
        // appears below the map card. It's safe, for now, to assume that a map card will always
        // be shown when the map location is set.
        const offset = isScreenSm() ? [0, 150] : [0, 0];

        this.map.flyTo({
          center,
          offset,
          speed: 2,
          zoom: 12,
        });
      }
    },

    getMapState() {
      /* eslint camelcase: off */
      let visibleFavoriteLists = [];

      if (this.lists?.length) {
        visibleFavoriteLists = this.lists.map(({ id }) => id);
      }

      const defaultMapState = {
        bearing: 0,
        center: this.center,
        currentBaseMap: this.baseMap,
        currentOverlay: this.currentOverlay,
        isFullScreen: this.canBeFullScreen === false ? false : true,
        overlayFieldControlValue: 'temp',
        pitch: 0,
        showLocations: this.shouldShowLocations,
        visibleFavoriteLists,
        visibleLocationTypes: [],
        zoom: this.zoom,
      };

      // Escape hatch. Set the reset query string parameter to ignore any saved map states and
      // simply start over at the default state. e.g. https://opensnow.com/map?reset=reset
      if (this.$route.query.reset) {
        return defaultMapState;
      }

      // Get the map state from the URL and local storage. Both may be used.
      const mapStateFromUrl = this.shouldReplaceUrl
        ? this.getMapStateFromUrl({ $route: this.$route })
        : {};

      const savedMapState = this.canSaveMapState ? this.getSavedMapState() : {};

      if (this.canBeFullScreen === false) {
        savedMapState.isFullScreen = false;
      }

      // Remove invalid fields from the map state obtained from the URL
      const filteredMapStateFromUrl = this.filterMapState({
        isAllAccess: this.isAllAccess,
        mapState: mapStateFromUrl,
      });

      // Remove invalid fields from the map state obtained from local storage
      const filteredSavedMapState = this.filterMapState({
        isAllAccess: this.isAllAccess,
        mapState: savedMapState,
      });

      // Return the merged map state. The map state from the URL will override the map state
      // obtained from local storage, which in turn, will be merged to the default map state.
      const newMapState = {
        ...defaultMapState,
        ...filteredSavedMapState,
        ...filteredMapStateFromUrl,
      };

      // If the user cannot toggle locations, i.e. the Locations Toggle is not displayed in the
      // control bar then always show the locations. In this case, individual location types can be
      // toggled on/off in the Options tab of the Layer Drawer.
      if (!this.canToggleLocations) {
        newMapState.showLocations = true;
      }

      return newMapState;
    },

    handleBeforeDestroy(shouldResetState = true) {
      if (this.map) {
        console.log('Map.handleBeforeDestroy(): Enter');

        window.clearTimeout(this.handleMapLoadedTimeout);
        this.handleMapLoadedTimeout = null;

        // Clean up the map instance
        if (this.isMapLoaded) {
          this.removeOverlays();
          this.removeMarker();
        }

        this.map.off('click', this.handleClick);
        this.map.off('mousedown', this.handleMouseDown);
        this.map.off('moveend', this.handleMoveEnd);
        this.map.off('zoomend', this.handleZoomend);

        if (shouldResetState) {
          // Reset the state so any changes made in the mini map are not seen on the Maps page
          this.resetState();
        }

        // Return the map instance to the map service
        this.$mapService.returnMap();
        this.map = null;

        console.log('Map.handleBeforeDestroy(): Exit');
      }

      window.removeEventListener('resize', this.handleResizeListener);
    },

    /**
     * "Throttle" click events by waiting a short delay for the <LocationsOverlay> or
     * <AvalancheOverlay> to handle them first. If neither overlay has handled the event by the
     * time this click handler is called then display a current forecast for the clicked location.
     *
     * For history on this approach, please see:
     *
     * https://github.com/cloudninewx/OpenMountain-Web/commit/f386eb9768f1a06f7dd62f2d4624f45bd29c6641
     */
    handleClick(e) {
      window.setTimeout(async () => {
        if (e.originalEvent.handledBy) {
          return;
        }

        // TODO: Close <MapCard> if custom location map card is open! Or get forecast for next
        // clicked location?
        try {
          const coordinates = Object.values(e.lngLat);
          await this.setMapCustomLocation({ coordinates });
        }
        catch (exp) {
          this.setMapUiProperties({ mapCard: null });
          const message = exp?.messages?.[0] || 'Unable to get forecast for this location!';
          this.$toast.open({ message, type: 'error' });
        }
      }, 150);

      this.map.getCanvas().style.cursor = 'pointer';
    },

    /**
     * @todo It seems redundant and potentially confusing to set many of the map UI properties here
     * in handleMapLoaded() that have _already_ been set in initMap(). It's not clear why this is
     * happening and should be investigated, and potentially removed, to reduce confusion.
     */
    async handleMapLoaded() {
      console.log('Map.handleMapLoaded(): Enter');

      if (!this.map) {
        console.log('Map.handleMapLoaded(): Exit early, map is null');
        return;
      }

      const {
        bearing,
        center,
        currentOverlay,
        isFullScreen,
        pitch,
        shortname,
        showLocations,
        zoom,
      } = this.savedMapStateToLoad;

      this.setMapUiProperties({
        bearing,
        center,
        currentOverlay,
        isFullScreen,
        pitch,
        showLocations,
        zoom,
      });

      await this.$nextTick();

      if (shortname) {
        if (shortname.match(/avy/)) {
          const payload = { id: shortname.replace(/^avy-/, '') };
          await this.setAvalancheCard(payload);
        }
        else {
          await this.setMapLocation({ shortname });
        }
      }

      if (this.padding && !this.bounds) {
        this.map.setPadding(this.padding);
      }

      this.setContourUnits();

      if (this.is3D) {
        this.setup3D(false);
      }
      else {
        this.setup2D(false);
      }

      if (this.markerLngLat) {
        this.addMarker(this.markerLngLat, this.map);
      }

      this.isMapLoaded = true;
      this.map.off('load', this.handleMapLoaded);
      this.map.resize();

      console.log('Map.handleMapLoaded(): Exit');

      this.$emit('map-loaded');

      if (shortname) {
        await this.$nextTick();
        this.flyToMapLocation();
      }
    },

    handleMouseDown() {
      this.map.getCanvas().style.cursor = 'grab';
    },

    /**
     * Save the bearing, center, and pitch to the Map Vuex module when the map moveend event fires
     * so they can be persisted to local storage and updated in the URL.
     */
    handleMoveEnd() {
      const bearing = bearing360(this.map.getBearing());
      const center = this.map.getCenter();
      const pitch = this.map.getPitch();

      this.setMapUiProperties({ bearing, center, pitch });

      this.map.getCanvas().style.cursor = 'pointer';
    },

    handleResize() {
      if (this.map) {
        this.map.resize();
      }
    },

    /**
     * Save the current zoom level to the Map Vuex module when the map zoomend event fires so that
     * it can be persisted to local storage and updated in the URL.
     * @see https://docs.mapbox.com/mapbox-gl-js/api/map/#map.event:zoomend
     */
    handleZoomend() {
      const zoom = this.map.getZoom();
      this.setMapUiProperties({ zoom });
    },

    zoomToOverlayExtent() {
      if (this.overlayCoverageBbox) {
        this.zoomToBbox(this.overlayCoverageBbox);
      }
      else {
        // just default to the whole globe, note that
        // this shouldn't ever happen, but better
        // safe than sorry
        this.zoomToBbox([-180, -90, 180, 90]);
      }
    },

    zoomToBbox(bbox) {
      this.map.fitBounds(bbox,
        {
          padding: {
            top: 10, bottom: 20, left: 10, right: 10,
          },
        });
      // make sure the UI gets set in the state correctly
      this.handleMoveEnd();
      this.handleZoomend();
    },

    openLayerDrawer() {
      // make sure it goes to layers and not to basemaps or
      // settings
      this.setMapUiProperties({ selectedLayerDrawerTab: 0 });
      this.setIsLayerDrawerOpen(true);
    },

    async init() {
      try {
        await this.initMap();
      }
      catch (e) {
        console.warn(`Unable to load map! ${e.toString()}`);
        this.unableToLoadMap = true;
        this.$emit('unable-to-load-map', e);
      }

      this.$loading.finish();
    },

    async initMap() {
      console.log('Map.initMap(): Enter');

      // If passed map state then use it, otherwise get it from various potential sources.
      const savedMapState = this.savedMapState ?? this.getMapState();

      const {
        bearing,
        center,
        currentBaseMap,
        overlayFieldControlValue,
        pitch,
        visibleFavoriteLists,
        visibleLocationTypes,
        zoom,
      } = savedMapState;

      await this.setCurrentBaseMap(currentBaseMap);

      // Reset current overlay when initializing the map so that when navigating to the /map page
      // from another Vue page, and there is already an active overlay, then the isActive watch in
      // the OverlayMixin is triggered. If this wasn't done the overlay would already be active,
      // the watch not triggered, and the overlay wouldn't be displayed in the map.
      this.setMapUiProperties({
        bearing,
        currentOverlay: null,
        overlayFieldControlValue,
        pitch,
        visibleFavoriteLists,
        visibleLocationTypes,
      });

      // After setting the current base map wait for the next tick to allow Vue to run updates so
      // that this.currentBaseMap is defined when creating the Map below.
      await this.$nextTick();

      // Enable/disable rotation if the compass cannot be displayed and enable/disable 3D settings
      // if the toggle 3D control can be displayed.
      const dragRotate = this.canShowCompass;
      const maxPitch = this.canShowToggle3DControl ? 75 : 0;

      const { map, willLoad } = this.$mapService.getMap({
        attributionControl: false,
        bearing,
        bounds: this.bounds,
        center,
        container: this.$refs.mapContainer,
        dragRotate,
        fadeDuration: 150,
        keyboard: false,
        maxPitch,
        pitch,
        projection: 'mercator',
        style: this.currentBaseMap.style_url,
        touchPitch: false,
        zoom,
      });

      if (!map) {
        throw new Error('Mapbox GL not supported!');
      }

      this.map = map;

      this.savedMapStateToLoad = savedMapState;

      if (willLoad) {
        this.map.on('load', this.handleMapLoaded);
      }

      this.addMouseEvents();
      this.addResizeHandler();

      if (this.canShowForecastAnywhere) {
        this.map.on('click', this.handleClick);
      }

      this.map.on('zoomend', this.handleZoomend);

      if (!willLoad) {
        this.handleMapLoadedTimeout = window.setTimeout(() => this.handleMapLoaded(), 1000);
      }

      console.log('Map.initMap(): Exit');
    },

    removeOverlays() {
      if (this.$refs.overlays) {
        this.$refs.overlays.forEach((overlay) => overlay.remove());
      }
    },

    resetMap(setStyle = false) {
      const addOverlays = () => {
        this.$refs.overlays.forEach((overlay) => {
          if (overlay.isActive) {
            overlay.add();
          }
        });
      };

      const resetMap = () => {
        if (this.is3D) {
          this.setup3D(false);
        }
        else {
          this.setup2D(false);
        }

        addOverlays();
        this.setContourUnits();
      };

      this.$refs.overlays.forEach((overlay) => overlay.remove());

      if (setStyle === true) {
        // Finish resetting the map a short time after the last styledata event is fired
        const handleStyledata = debounce(() => {
          this.map.off('styledata', handleStyledata);
          resetMap();
        }, 500);

        this.map.on('styledata', handleStyledata);
        this.map.setStyle(this.currentBaseMap.style_url, false);
      }
      else {
        resetMap();
      }
    },

    setContourUnits() {
      if (this.map.getLayer('contour-label')) {
        const value = this.units === 'imperial'
          ? ['concat', ['number-format', ['*', ['round', ['/', ['*', 3.28084, ['get', 'ele']], 10]], 10], {}], ' ft']
          : ['concat', ['number-format', ['*', ['round', ['/', ['get', 'ele'], 5]], 5], {}], ' m'];

        this.map.setLayoutProperty('contour-label', 'text-field', value);
      }
    },

    setup2D(resetBearing = true) {
      const mapboxDem = 'mapbox-dem';

      this.map.setFog(null);
      this.map.setTerrain(null);

      if (this.map.getSource(mapboxDem)) {
        this.map.removeSource(mapboxDem);
      }

      if (resetBearing) {
        this.map.easeTo({ bearing: 0, pitch: 0 });
      }
      else {
        this.map.easeTo({ pitch: 0 });
      }
    },

    setup3D(resetBearingAndPitch = true) {
      const mapboxDem = 'mapbox-dem';

      // Add the DEM source if not already present
      if (!this.map.getSource(mapboxDem)) {
        this.map.addSource(mapboxDem, {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 14,
        });
      }

      // Add the DEM source as a terrain layer with exaggerated height
      this.map.setTerrain({
        source: mapboxDem,
        exaggeration: 1.2,
      });

      this.map.setFog({
        'color': 'white',
        'horizon-blend': 0.05,
        'range': [-1, 20],
      });

      if (resetBearingAndPitch) {
        this.map.easeTo({ bearing: 0, pitch: 65 });
      }
    },

    toggle3D() {
      if (!this.map) {
        return;
      }

      const reset = this.shouldReset3D;

      if (this.is3D) {
        this.setup3D(reset);
      }
      else {
        this.setup2D(reset);
      }

      this.setMapUiProperties({ shouldReset3D: false });
    },
  },
};
</script>

<style module>
.mapCardClass {
  width: 100%;
}

@media (min-width: 992px) {
  .mapCardClass {
    width: 500px;
  }
}
</style>
