<template>
  <div
    v-if="legend"
    id="overlayLegend"
    :class="containerClass"
    :style="containerStyle"
  >
    <span
      v-if="!legend.groups"
      :class="unitsClass"
    >
      {{ unitsText }}
    </span>
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="tw-inline-block tw-flex-grow"
      :style="step.style"
    >
      <p :class="step.class">
        {{ step.value }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import {
  getSnowUnits,
  getTemperatureUnits,
  getWindUnits,
} from '@@/utils/CommonUtils';
import { invertColor } from '@@/components/Maps/Overlays/Utils';
import { useMapStore } from '@@/stores/Map';
import { useUserStore } from '@@/stores/User';

export default {
  name: 'OverlayLegend',

  data() {
    return {
      steps: [],
      containerStyle: null,
    };
  },

  computed: {
    ...mapState(useMapStore, [
      'currentBaseMap',
      'currentOverlay',
      'currentOverlayLegend',
    ]),

    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),

    containerClass() {
      return [
        'tw-flex',
        'tw-justify-evenly',
        'tw-h-4',
        'card-background-color',
        'tw-text-xs',
      ];
    },

    legend() {
      if (this.currentOverlay?.legends?.length > 1) {
        return this.currentOverlayLegend;
      }

      return this.currentOverlay?.legends?.[0];
    },

    unitsText() {
      const units = this.legend.units_abbrev;
      const temperatureUnits = getTemperatureUnits(this.units);
      const snowUnits = getSnowUnits(this.units);
      const windUnits = getWindUnits(this.units);

      if (units === 'in' && units !== snowUnits) {
        return snowUnits;
      }

      if (units === 'mph' && units !== windUnits) {
        return windUnits;
      }

      if (units === '°F' && units !== temperatureUnits) {
        return temperatureUnits;
      }

      return units;
    },

    unitsClass() {
      return [
        'tw-inline-block',
        'tw-border-r',
        'border-color',
        'tw-flex-grow',
        'tw-text-center',
      ];
    },
  },

  watch: {
    currentBaseMap() {
      if (this.currentOverlay) {
        this.renderLegend();
      }
    },

    currentOverlay(value) {
      if (value) {
        this.renderLegend();
      }
    },

    legend() {
      if (this.currentOverlay) {
        this.renderLegend();
      }
    },

    units() {
      if (this.currentOverlay) {
        this.renderLegend();
      }
    },
  },

  mounted() {
    this.renderLegend();
  },

  methods: {
    getColor(step) {
      /* eslint camelcase: off */
      const { short_name } = this.currentBaseMap || {};
      // TODO: Consider adding this logic as a getter in the Map vuex module!
      const useDarkColor = short_name === 'dark' || short_name === 'satellite';
      return step.color_dark && useDarkColor ? step.color_dark : step.color_light;
    },

    renderLegend() {
      if (!this.legend) {
        return;
      }

      if (!this.legend.groups) {
        this.renderStepLegend();
      }
      else {
        this.renderGroupLegend();
      }
    },

    renderGroupLegend() {
      const colors = this.legend.steps.map((step) => this.getColor(step));
      const useGradient = !(['avalanche', 'public-lands', 'hail-size', 'fall-colors', 'c9-snowfall-total'].includes(this.currentOverlay.short_name));
      let stepIndex = 0;

      this.steps = this.legend.groups.map((group) => {
        let backgroundColor;
        let color;
        let count = 0;

        for (;stepIndex < this.legend.steps.length; stepIndex += 1) {
          const step = this.legend.steps[stepIndex];

          if (step.value === group.max_value) {
            backgroundColor = this.getColor(step);

            // Compare against middle bg color in the gradient
            color = invertColor(
              this.getColor(this.legend.steps[stepIndex - Math.floor(count / 2)]),
            );
            break;
          }

          count += 1;
        }
        const stepData = {
          style: {
            backgroundColor: useGradient ? undefined : backgroundColor,
            color,
          },
          class: ['tw-text-center', 'tw-bg-transparent'],
          value: group.label,
        };
        return stepData;
      });

      this.containerStyle = {
        background: useGradient ? `linear-gradient(0.25turn, ${colors.join(',')}` : undefined,
      };
    },

    /**
     * @todo Use conversion methods from node-forecasts-lib!
     * @see https://github.com/cloudninewx/node-forecasts-lib
     */
    renderStepLegend() {
      const labelsOnBreaks = ['c9-snowfall-total', 'c9-snowfall-6h', 'c9-snowfall-12h', 'c9-snowfall-24h'].includes(this.currentOverlay.short_name);
      this.steps = this.legend.steps.map((step, index) => {
        const backgroundColor = this.getColor(step);
        const color = invertColor(backgroundColor);

        let { value } = step;

        if (this.unitsText === 'cm') {
          // Convert to cms and round to nearest 5
          value = Math.max(1, Math.round((value * 2.54) / 5) * 5);
        }
        else if (this.unitsText === '°C') {
          // Convert to celsius and ceil to nearest 2 (ceil instead of round so we will hit 0 on
          // the nose)
          value = Math.ceil(((value - 32) * (5 / 9)) / 2) * 2;
        }
        else if (this.unitsText === 'kmh') {
          value = Math.round((value * 1.609) / 5) * 5;
        }

        if (index === this.legend.steps.length - 1
          && ['in', 'cm', 'mph', 'kmh', 'Detected hours ago', '°F', '°C'].includes(this.unitsText)
          && !labelsOnBreaks) {
          value += '+';
        }

        const stepData = {
          style: { backgroundColor, color },
          value,
          class: ['tw-bg-transparent'],
        };
        if (labelsOnBreaks) {
          stepData.class.push('tw--translate-x-1/2');
          stepData.class.push('tw-w-fit');
          stepData.style.textShadow = '0 0 2px #000',
          stepData.style.color = '#fff';
        }
        else {
          stepData.class.push('tw-text-center');
        }
        return stepData;
      });

      if (labelsOnBreaks) {
        this.steps[0].value = '';
      }

      this.containerStyle = null;
    },
  },
};
</script>
